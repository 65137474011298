// src/pages/Documents/context/DocumentContext.tsx
import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { initialState } from './State';
import { DocumentState } from './Interfaces'
import { FormAction } from './Actions';
import DocumentReducer from './GroupReducer';

const DocumentStateContext = createContext<DocumentState | undefined>(undefined);
const DocumentDispatchContext = createContext<React.Dispatch<FormAction> | undefined>(undefined);

export const useDocumentState = () => {
    const context = useContext(DocumentStateContext);
    if (!context) {
        throw new Error('useDocumentState must be used within a DocumentProvider');
    }
    return context;
};

export const useDocumentDispatch = () => {
    const context = useContext(DocumentDispatchContext);
    if (!context) {
        throw new Error('useDocumentDispatch must be used within a DocumentProvider');
    }
    return context;
};

interface DocumentProviderProps {
    children: ReactNode;
}

export const DocumentProvider: React.FC<DocumentProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(DocumentReducer, initialState);

    return (
        <DocumentStateContext.Provider value={state}>
            <DocumentDispatchContext.Provider value={dispatch}>
                {children}
            </DocumentDispatchContext.Provider>
        </DocumentStateContext.Provider>
    );
};
