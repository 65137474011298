import React from 'react';

interface ListProps {
    items: any[];
}

const List: React.FC<ListProps> = ({ items }) => {
    const formatdata = (item: any) => {
        if (item['label']) {
            return item['label'];
        } else if (item['first_name'] || item['last_name']) {
            return `${item['first_name']} ${item['last_name']}`
        }
    }
    return (
        <div className='managealertspopup'>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>{formatdata(item)}</li>
                ))}
            </ul>
        </div>
    );
};

export default List;
