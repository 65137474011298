// src/pages/Newss/context/NewsContext.tsx
import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { initialState } from './State';
import { NewsState } from './Interfaces'
import NewsReducer from './NewsReducer';
import { FormAction } from './Actions';

const NewsStateContext = createContext<NewsState | undefined>(undefined);
const NewsDispatchContext = createContext<React.Dispatch<FormAction> | undefined>(undefined);

export const useNewsState = () => {
    const context = useContext(NewsStateContext);
    if (!context) {
        throw new Error('useNewsState must be used within a NewsProvider');
    }
    return context;
};

export const useNewsDispatch = () => {
    const context = useContext(NewsDispatchContext);
    if (!context) {
        throw new Error('useNewsDispatch must be used within a NewsProvider');
    }
    return context;
};

interface NewsProviderProps {
    children: ReactNode;
}

export const NewsProvider: React.FC<NewsProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(NewsReducer, initialState);

    return (
        <NewsStateContext.Provider value={state}>
            <NewsDispatchContext.Provider value={dispatch}>
                {children}
            </NewsDispatchContext.Provider>
        </NewsStateContext.Provider>
    );
};
