import React, { ChangeEvent, useEffect, useState } from "react";
import TableStructure from "../../../atoms/TableStructure";
import { APICALL } from "../../../../services/ApiServices";
import { createUser, manageUser } from "../../../../routes/ApiEndpoints";
import { filterOptions, manageState, tableHeaders, tableValues } from "../State";
import ManageUserFilters from "./ManageUserFilters";
import { useNavigate } from "react-router-dom";
import Button from "../../../atoms/Button";
import { t } from "../../../../services/translation/TranslationUtils";
import Pagination from "../../../molecules/Paginations";
import ManageUserTabs from "./ManageUserTabs";
import Popup from "../../../molecules/Popup";
import ImportUsers from "./ImportUsers";

const ManageUserOrganism: React.FC = () => {
    const [state, setState] = useState(manageState);
    const navigate = useNavigate();

    useEffect(() => {
        fetchData(manageState.filters);
    }, []);

    const fetchData = async (filters: any = {}) => {
        let postData = {
            options: filterOptions,
            ...filters
        }
        const response = await APICALL.service(manageUser, 'POST', postData);

        if (response.status === 200) {
            let responseData = response.data;
            setState((prevState: any) => ({
                ...prevState,
                data: responseData.data,
                options: response.options,
                filters: {
                    ...prevState.filters,
                    total_pages: responseData.total_pages
                }
            }))
        }
    }

    const handleEdit = (value: any) => {
        navigate(`/employee/create?id=${value.id}`);
    }

    const handleClone = (value: any) => {
        navigate(`/employee/create?clone=${value.id}`);
    }

    const handleArchive = async (value: any) => {
        let postData = {
            user_id: value.id,
            status: !value.activity,
            status_change: true
        }
        const response = await APICALL.service(createUser, 'POST', postData);

        if (response.status === 200) {
            setState((prevState: any) => ({
                ...prevState,
                popup: false
            }));
            fetchData(state.filters);
        }
    }

    const handleArchivePopup = async (value: any) => {
        setState((prevState: any) => ({
            ...prevState,
            popup: value
        }));
    }

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: string
    ) => {
        setState((prevState: any) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [field]: e.target instanceof HTMLInputElement ? e.target.value : e
            }
        }));
    }

    const handleClear = () => {
        setState((prevState: any) => ({
            ...prevState,
            filters: manageState.filters
        }));
        fetchData(manageState.filters);
    }

    const handlePaginationClick = (pageNumber: number) => {
        let filters = {
            ...state.filters,
            current_page: pageNumber
        }
        setState((prevState: any) => ({
            ...prevState,
            filters: filters
        }));
        fetchData(filters);
    };

    const handleTabsClick = (tab: string) => {
        let status = null;
        if (tab !== 'all') {
            status = tab === 'active';
        }
        let filters = {
            ...state.filters,
            status: status
        }
        setState((prevState: any) => ({
            ...prevState,
            filters: filters
        }));
        fetchData(filters);
    };

    return (
        <>
            <h2>{t("Manage employee")}</h2>
            <div className="d-flex justify-content-end mb-3 align-items-center">
                <Button
                    className={`create_button me-3`}
                    handleClick={() => navigate('/employee/create')}
                    title={'+ ' + t('Create employee')}
                />
                <ImportUsers fetchData={fetchData} />
            </div>
            <ManageUserFilters
                handleChange={handleChange}
                options={state.options}
                data={state?.filters}
                handleSubmit={() => fetchData(state.filters)}
                handleClear={handleClear}
            />
            <ManageUserTabs
                handleClick={handleTabsClick}
                status={state?.filters?.status}
            />
            <TableStructure
                isAction
                data={state.data}
                headers={tableHeaders}
                values={tableValues}
                handleEdit={handleEdit}
                handleArchive={handleArchivePopup}
                handleClone={handleClone}
            />
            {state?.filters?.total_pages > 1 &&
                <Pagination
                    currentPage={state?.filters?.current_page}
                    totalPages={state?.filters?.total_pages}
                    handlePaginationClick={handlePaginationClick}
                />
            }
            {state?.popup && <Popup
                body={t('Are you sure, you want to change the activity') + '?'}
                yestext={t('Yes')}
                notext={t('No')}
                submit={() => handleArchive(state.popup)}
                cancel={() => setState((prevState: any) => ({
                    ...prevState,
                    popup: false
                }))}
            />}
        </>
    );
};

export default ManageUserOrganism;
