import React from 'react'

export const Surver = () => {
  return (
    <svg version="1.0"
      width="35px" height="35px" viewBox="0 0 225.000000 225.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M142 2148 c-55 -12 -116 -69 -131 -123 -15 -56 -15 -1744 0 -1800 16
-58 75 -110 139 -124 71 -15 1689 -15 1760 0 61 13 106 49 131 104 17 37 19
75 19 494 l0 455 84 85 c60 62 87 98 96 128 30 98 -39 207 -138 220 l-42 6 0
206 c0 178 -3 211 -19 246 -25 55 -70 91 -131 104 -65 14 -1705 13 -1768 -1z
m119 -188 c26 -14 24 -55 -3 -74 -19 -13 -25 -14 -43 -2 -27 18 -32 56 -9 73
22 16 30 16 55 3z m193 -6 c23 -23 20 -51 -8 -69 -45 -30 -91 20 -59 64 17 25
46 27 67 5z m191 -8 c18 -26 11 -52 -16 -67 -43 -22 -86 36 -53 72 23 26 50
24 69 -5z m1235 -364 l0 -107 -223 -223 -222 -222 -463 0 c-300 0 -470 -4
-483 -10 -21 -12 -25 -50 -7 -68 9 -9 122 -12 445 -12 238 0 433 -1 433 -3 0
-1 -11 -55 -24 -120 -14 -65 -22 -125 -20 -132 10 -26 45 -27 167 -4 l122 23
130 128 c72 70 134 128 138 128 4 0 7 -153 7 -340 l0 -340 -845 0 -845 0 0
705 0 705 845 0 845 0 0 -108z m123 -240 l57 -58 -33 -32 -33 -32 -59 60 -59
60 29 30 c16 16 31 30 35 30 3 0 31 -26 63 -58z"/>
        <path d="M482 1298 c-18 -18 -14 -56 7 -68 30 -15 1073 -14 1089 2 7 7 12 21
12 33 0 12 -5 26 -12 33 -17 17 -1079 17 -1096 0z"/>
        <path d="M486 734 c-19 -18 -21 -45 -4 -62 17 -17 609 -17 626 0 15 15 15 51
0 66 -9 9 -91 12 -309 12 -257 0 -300 -2 -313 -16z"/>
      </g>
    </svg>

  )
}
