import React, { useEffect, useState } from 'react';
import InputTextfield from '../atoms/InputTextField';
import Button from '../atoms/Button';
import CheckBoxField from '../atoms/CheckBoxField';
import { InputWithLabel } from '../atoms/InputWithLabel';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LabelField from '../atoms/LabelField';
import FormValidation from '../../services/formvalidation/Validation';
import ErrorMessage from '../atoms/ErrorMessage';
import { Form, Survey } from '../organisms/posts/Types';
import MultiSelect from '../atoms/MultiSelectField';
import { Option } from '../common/CommonInterfaces';
import Edit from '../../utils/icons/Edit';
import { Delete } from '../../utils/icons/Delete';
import { t } from '../../services/translation/TranslationUtils';

interface FormState {
    formData: Survey;
    optionText: string;
    editingIndex: number | null;
    errors: {
        optionText: string;
        vote_until: string;
        max_number_of_answers: string;
        min_number_of_answers: string;
        survey_permissions: string;
    };
}

interface Props {
    handleSubmit: (data: Survey) => void;
    state: any,
    survey?: Survey | any
}

const AddOptionsMolecule: React.FC<Props> = ({ handleSubmit, state, survey }) => {
    const [formState, setFormState] = useState<FormState>({
        formData: {
            ismulti: false,
            max_number_of_answers: 1,
            min_number_of_answers: 1,
            vote_until: null,
            survey_options: [],
            survey_permissions: []
        },
        optionText: '',
        editingIndex: null,
        errors: {
            optionText: '',
            vote_until: '',
            max_number_of_answers: '',
            min_number_of_answers: '',
            survey_permissions: ''
        }
    });

    useEffect(() => {
        if (survey != undefined && survey != null) {
            setFormState((prev) => ({
                ...prev,
                formData: survey
            }));
        }
    }, [survey != undefined && survey != null])
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | Date | any, name?: string) => {        
        if (name) {
            if (e != null && typeof e === 'object' && 'target' in e) {
                const { value, type, checked } = e.target;
                if (type === 'number' && value.length > 10) return; // Validation for number fields

                setFormState((prev) => ({
                    ...prev,
                    formData: {
                        ...prev.formData,
                        [name]: type === 'checkbox' ? checked : type === 'number' ? ((value && parseInt(value) > 1) ? parseInt(value, 10) : 1) : value
                    }
                }));
            } else if (name === 'survey_permissions' || e === null || e instanceof Date) {                             
                setFormState((prev) => ({
                    ...prev,
                    formData: {
                        ...prev.formData,
                        [name]: e
                    }
                }));
            }
        }
    };
    
    const handleOptionAction = (action: 'add' | 'edit' | 'delete', index?: number | null) => {
        switch (action) {
            case 'add':
                const error = FormValidation.nameValidation(formState.optionText.trim());
                if (!error) {
                    const updatedOptions = formState.editingIndex !== null
                        ? formState.formData.survey_options.map((opt, i) => (i === formState.editingIndex ? formState.optionText : opt))
                        : [...formState.formData.survey_options, formState.optionText];

                    setFormState((prev) => ({
                        ...prev,
                        formData: {
                            ...prev.formData,
                            survey_options: updatedOptions
                        },
                        errors: {
                            ...prev.errors,
                            optionText: ''
                        },
                        optionText: '',
                        editingIndex: null
                    }));
                } else {
                    setFormState((prev) => ({
                        ...prev,
                        errors: {
                            ...prev.errors,
                            optionText: error
                        }
                    }));
                }
                break;

            case 'edit':
                if (index !== undefined && index !== null) {
                    setFormState((prev) => ({
                        ...prev,
                        editingIndex: index,
                        optionText: formState.formData.survey_options[index]
                    }));
                }
                break;

            case 'delete':
                if (index !== undefined) {
                    setFormState((prev) => ({
                        ...prev,
                        formData: {
                            ...prev.formData,
                            survey_options: prev.formData.survey_options.filter((_, i) => i !== index)
                        }
                    }));
                }
                break;

            default:
                break;
        }
    };

    const validate = () => {
        if (formState.formData.survey_options.length) {
            handleSubmit(formState.formData);
        } else {
            setFormState((prev) => ({
                ...prev,
                errors: {
                    ...prev.errors,
                    optionText: "Please create options"
                }
            }));
        }
    };

    return (
        <div className='px-3 row'>
            <div className="col-6 border-end">
                <div className='d-flex align-items-end'>
                    <div>
                        <InputWithLabel
                            type="text"
                            name="optionText"
                            placeholder={t("Add option")}
                            mainclass="mb-0 me-3"
                            label={t("Add option")}
                            value={formState.optionText}
                            handleChange={(e) => setFormState({ ...formState, optionText: e.target.value })}
                            handleClick={() => setFormState({ ...formState, errors: { ...formState.errors, optionText: '' } })}
                            error={formState.errors.optionText}
                        />
                    </div>
                    <Button
                        className='create_button height_maxcontent'
                        handleClick={() => handleOptionAction('add')}
                        title={formState.editingIndex !== null ? t('Save') : t('Add')}
                    />
                </div>
                <ul className='mt-3'>
                    {formState.formData.survey_options.map((option, index) => (
                        <li key={index} className='py-2 border-top'>
                            <div className="d-flex justify-content-between">
                                {option}
                                <div className='d-flex gap-2'>
                                    <div onClick={() => handleOptionAction('edit', index)}><Edit /></div>
                                    <div onClick={() => handleOptionAction('delete', index)}><Delete /></div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="col-6">
                <div className='mb-2'>
                    <MultiSelect
                        name={'survey_permissions'}
                        className={'shadow-none'}
                        classNamePrefix={'react-select-prefix'}
                        placeholder={t('select')}
                        options={state.user_options?.User}
                        standards={state.user_options?.User?.filter((option: any) =>
                            formState.formData?.survey_permissions?.includes(option?.value)
                        )}
                        handleChange={(selectedValues) =>
                            handleChange(
                                (selectedValues as any[]).map((option) => option?.value),
                                'survey_permissions'
                            )
                        }
                        customStyle={
                            formState.errors.survey_permissions
                                ? { border: '1px solid red', borderRadius: '5px' }
                                : undefined
                        }
                        handleClick={() => setFormState((prev) => ({ ...prev, errors: { ...formState.errors, survey_permissions: '' } }))}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={true}
                        disabled={false}
                    />
                    <ErrorMessage errorMessage={formState.errors.survey_permissions} />
                </div>
                <div className='mb-2'>
                    <CheckBoxField
                        name="ismulti"
                        label={t("Is Multi")}
                        checked={formState.formData.ismulti}
                        onCheck={(e) => handleChange(e, 'ismulti')}
                    />
                </div>
                {formState.formData.ismulti && (
                    <div>
                        <div className='mb-2'>
                            <InputWithLabel
                                type="number"
                                name="min_number_of_answers"
                                label={t("Min Number of Answers")}
                                value={formState.formData.min_number_of_answers}
                                handleChange={(e) => handleChange(e, 'min_number_of_answers')}
                                handleClick={() => setFormState({ ...formState, errors: { ...formState.errors, min_number_of_answers: '' } })}
                                error={formState.errors.min_number_of_answers}
                            />
                        </div>
                        <span className='mb-2'>
                            <InputWithLabel
                                type="number"
                                name="max_number_of_answers"
                                label={t("Max Number of Answers")}
                                value={formState.formData.max_number_of_answers}
                                handleChange={(e) => handleChange(e, 'max_number_of_answers')}
                                handleClick={() => setFormState({ ...formState, errors: { ...formState.errors, max_number_of_answers: '' } })}
                                error={formState.errors.max_number_of_answers}
                            />
                        </span>
                    </div>
                )}
                <div className='mt-3'>
                    <LabelField title={t("Vote Until")} />
                    <DatePicker
                        selected={formState.formData.vote_until}
                        onChange={(date: Date | null) => handleChange(date, 'vote_until')}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        placeholderText="DD/MM/YYYY"
                        minDate={new Date()}
                        className="ps-2 w-100 form-control"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        isClearable={formState.formData.vote_until ? true : false}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            const validKeys = ['Backspace', 'Delete', '/', '-', 'ArrowLeft', 'ArrowRight', 'A', 'P', 'M', 'a', 'p', 'm', ' ', ':'];
                            if (
                                !validKeys.includes(e.key) &&
                                (isNaN(Number(e.key)) || (e.currentTarget.value && e.currentTarget.value.length >= 22))
                            ) {
                                e.preventDefault();
                            }
                        }}
                    />
                    <ErrorMessage errorMessage={formState.errors.vote_until} />
                </div>
            </div>
            <div className="col-12 text-end my-3">
                <button onClick={validate} className='create_button mt-3 '>{t("Submit")}</button>
            </div>
        </div>
    );
};

export default AddOptionsMolecule;
