// src/pages/groups/context/GroupContext.tsx
import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { initialState } from './State';
import { GroupState } from './Interfaces'
import GroupReducer from './GroupReducer';
import { FormAction } from './Actions';

const GroupStateContext = createContext<GroupState | undefined>(undefined);
const GroupDispatchContext = createContext<React.Dispatch<FormAction> | undefined>(undefined);

export const useGroupState = () => {
    const context = useContext(GroupStateContext);
    if (!context) {
        throw new Error('useGroupState must be used within a GroupProvider');
    }
    return context;
};

export const useGroupDispatch = () => {
    const context = useContext(GroupDispatchContext);
    if (!context) {
        throw new Error('useGroupDispatch must be used within a GroupProvider');
    }
    return context;
};

interface GroupProviderProps {
    children: ReactNode;
}

export const GroupProvider: React.FC<GroupProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(GroupReducer, initialState);

    return (
        <GroupStateContext.Provider value={state}>
            <GroupDispatchContext.Provider value={dispatch}>
                {children}
            </GroupDispatchContext.Provider>
        </GroupStateContext.Provider>
    );
};
