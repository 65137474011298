import React, { ChangeEvent, useEffect, useState } from "react";
import ImageField from "../../components/atoms/ImageField";
import InputTextfield from "../../components/atoms/InputTextField";
import LabelField from "../../components/atoms/LabelField";
import Button from "../../components/atoms/Button";
import { APICALL } from "../../services/ApiServices";
import { forgotPassword, generateOtp } from "../../routes/ApiEndpoints";
import { useNavigate } from "react-router-dom";
import CustomNotify from "../../components/atoms/CustomNotify";
import Closeeye from "../../utils/icons/Closeeye";
import Openeye from "../../utils/icons/Openeye";
import FormValidation from "../../services/formvalidation/Validation";

const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        otp: '',
        password: '',
        confirm_password: '',
        password_type: 'password',
        confirm_password_type: 'password',
        timer: 0
    });

    useEffect(() => {
        if (state.timer > 0) {
            // Set up the countdown timer
            const timerId = setInterval(() => {
                setState(prevState => {
                    if (prevState.timer > 1) {
                        return {
                            ...prevState,
                            timer: prevState.timer - 1
                        };
                    } else {
                        clearInterval(timerId);
                        return {
                            ...prevState,
                            timer: 0
                        };
                    }
                });
            }, 1000);

            // Cleanup interval on component unmount or when timer ends
            return () => clearInterval(timerId);
        }
    }, [state.timer]);

    const resendOtp = async () => {
        let postData = {
            type: 'reset',
            phone: localStorage.getItem('phone'),
            resend: true
        }
        const response = await APICALL.service(generateOtp, 'POST', postData);

        if (response.status === 200) {
            setState((prevState: any) => ({
                ...prevState,
                timer: 119
            }));
        } else {
            navigate('/forgot-password')
            CustomNotify({
                type: "error",
                message: "The phone number is not registered"
            });
        }
    }

    const changeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        let { name, value } = event.target;
        setState((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const validate = () => {
        let error = ''
        if (state.otp !== '' && state.otp !== null) {
            error = FormValidation.passwordValidation(state.password, state.confirm_password);
        } else {
            error = 'Please fill the required fields';
        }

        if (error == '') {
            return true;
        } else {
            CustomNotify({
                type: 'error',
                message: error
            });
            return false;
        }
    }

    const submitHandler = async (
        event: React.MouseEvent<HTMLButtonElement | HTMLSpanElement, MouseEvent>
    ) => {
        event.preventDefault();
        let validated = validate();

        if (validated) {
            let postData = {
                otp: state?.otp,
                password: state?.password
            }
            const response = await APICALL.service(forgotPassword, 'POST', postData);
            
            if (response.status === 200) {
                localStorage.removeItem('phone');
                navigate('/login');
                CustomNotify({
                    type: 'success',
                    message: response.message
                })
            } else {
                CustomNotify({
                    type: 'error',
                    message: response.error
                })
            }
        }
    }

    const formatTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const togglePassword = (field: string) => {
        const variable = `${field}_type`;
    
        setState((prevState: any) => {
            const newType = prevState[variable] === 'password' ? 'text' : 'password';
            return { ...prevState, [variable]: newType };
        });
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xxl-7 col-xl-6 col-lg-6 vh-100 ps-0 justify-content-center align-items-center vh-100 ps-0 d-none d-md-none d-lg-block">
                    <ImageField
                        altText="Banner"
                        className="w-100 h-100 img-fluid object-fit-cover"
                        value="/static/images/loginImage.jpg"
                    />
                </div>
                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-8 col-sm-8 m-auto register-form__fields p-xxl-3 p-xl-0 p-lg-0">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-7 m-auto px-xxl-1 p-lg-0">
                            <div className="text-center mb-5">
                                <ImageField
                                    altText="Logo"
                                    className="text-center logo-width"
                                    value="static/images/logo.svg"
                                />
                            </div>
                            <form className="login-form">
                                <h2 className="text-center mb-4 login-title fw-bold">
                                    {" "}
                                    {"Reset password"}
                                </h2>
                                <div className="mb-4">
                                    <LabelField
                                        title={"OTP"}
                                        mandatory={true}
                                    />
                                    <div className="d-flex gap-2">
                                        <InputTextfield
                                            handleChange={changeHandler}
                                            value={state.otp}
                                            placeholder={"Enter your OTP"}
                                            name="otp"
                                            className="form-control flex-1 shadow-none rounded-0"
                                        />
                                        {state.timer == 0 ?
                                            <Button
                                                className="create_button"
                                                title={'Resend'}
                                                handleClick={resendOtp}
                                            /> :
                                            <Button
                                                title={formatTime(state.timer)}
                                                className="back_btn"
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <LabelField
                                        title={"New password"}
                                        mandatory={true}
                                    />
                                    <div className="d-flex position-relative">
                                        <InputTextfield
                                            handleChange={changeHandler}
                                            type={state.password_type}
                                            value={state.password}
                                            placeholder={"Enter your password"}
                                            name="password"
                                            className="form-control shadow-none rounded-0"
                                        />
                                        <p
                                            onClick={() => togglePassword('password')}
                                            className="position-absolute mt-1 me-2 end-0 cursor-pointer"
                                        >
                                            {state.password_type === "password" ? (
                                                <Closeeye />
                                            ) : (
                                                <Openeye />
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <LabelField
                                        title={"Confirm password"}
                                        mandatory={true}
                                    />
                                    <div className="d-flex position-relative">
                                        <InputTextfield
                                            handleChange={changeHandler}
                                            type={state.confirm_password_type}
                                            value={state.confirm_password}
                                            placeholder={"Confirm your password"}
                                            name="confirm_password"
                                            className="form-control shadow-none rounded-0"
                                        />
                                        <p
                                            onClick={() => togglePassword('confirm_password')}
                                            className="position-absolute mt-1 me-2 end-0 cursor-pointer"
                                        >
                                            {state.confirm_password_type === "password" ? (
                                                <Closeeye />
                                            ) : (
                                                <Openeye />
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <Button
                                    title={"Submit"}
                                    type="button"
                                    className="create_button mb-4 w-100 shadow-none rounded-0 text-uppercase"
                                    handleClick={submitHandler}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;