import CreatePostOrganism from "../../components/organisms/posts/CreatePostOrganism";

const CreatePost: React.FC = () => {
    return (
        <>
            {/* <CreatePostOrganism /> */}
        </>
    );
}

export default CreatePost;