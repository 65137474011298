import ManageGroupOrganism from "../../components/organisms/groups/manage/ManageGroupOrganism";

export const ManageGroup = () => {
    return (
        <>
            <ManageGroupOrganism />
        </>
    );
};
export default ManageGroup;
