import { FormMode } from '../../../components/common/CommonEnums';
import { Option } from '../../../components/common/CommonInterfaces';
import FormValidation from '../../../services/formvalidation/Validation';
import { NewsState, NewsStateTabs } from './Interfaces';

// Function to handle UPDATE_FIELD action
export const handleUpdateField = (tabState: any, state: NewsState, action: any): NewsState => {
    const checkboxFields = ['user', 'admin', 'team', 'moderator'];

    if (checkboxFields.includes(action?.field) && action.value?.value) {
        const data: any = tabState?.data;
        const { value, checked } = action.value;
        // Ensure the field in data is initialized as an array if undefined
        const currentArray = data[action.field] || [];
        action.value = checked
            ? [...currentArray, Number(value)]
            : currentArray.filter((item: any) => item != value);
    }
    
    return {
        ...state,
        [state.currentTab]: {
            ...tabState,
            data: {
                ...tabState.data,
                [action?.field]: action.value,
            },
            errors: {
                ...tabState.errors,
                [action?.field]: validateField(action?.field, action?.value)
            }
        },
    };
};

// Function to handle Set error action
export const handleSetError = (state: NewsState, action: any): NewsState => {
    if (action?.field) {
        return {
            ...state,
            [state.currentTab]: {
                ...state[state.currentTab],
                errors: {
                    ...state[state.currentTab].errors,
                    [action?.field]: ""
                }
            }
        };
    } else {
        let tab: keyof NewsStateTabs = action.tab;
        return {
            ...state,
            [action.tab]: {
                ...state[tab],
                errors: {
                    ...state[tab].errors,
                    ...action.errors,
                },
            },
            tabs: state.tabs.map(tab => 
                tab.id === action?.tab ? { ...tab, error: action?.hasError } : tab
            ),
        };
    }
};

// Function to handle Initial action
export const handleIntialStateUpdate = (state: NewsState, action: any): NewsState => {
    const { options, title, description, status, news_info, admin, user, team, moderator } = action?.value || {};    
    return {
        ...state,
        options,
        initialCall: false,
        mode: FormMode?.UPDATE,
        description: {
            ...state.description,
            data: {
                ...state.description.data,
                ...news_info,
                status,
                header: {
                    file: null,
                    file_name: news_info?.header?.file_name,
                    file_path: news_info?.header?.file_path
                },
                thumbnails: {
                    file: null,
                    file_name: news_info?.thumbnails?.file_name,
                    file_path: news_info?.thumbnails?.file_path
                },
                title: title,
                description: description,
            },
        },
        admin: {
            ...state.admin,
            data: {
                ...state.admin.data,
                admin,
            },
        },
        user: {
            ...state.user,
            data: {
                ...state.user.data,
                user,
            },
        },
        team: {
            ...state.team,
            data: {
                ...state.team.data,
                team
            },
        },
        moderator: {
            ...state.moderator,
            data: {
                ...state.moderator.data,
                moderator
            },
        },
    };
};

export const filterOptions = (options: Option[], search: string): Option[] => {
    if (!search || search === "") return options;
    const lowercasedSearch = search?.toLowerCase();
    return options?.filter((option: any) =>
        option?.label != null && option?.label?.toLowerCase()?.includes(lowercasedSearch)
    );
};

export const validateField = (field: string, value: any) => {
    let data = '';
    const fields: any = {
        group_type_id: {
            required: true,
            type: 'integer'
        },
        title: {
            required: true,
            type: 'string'
        },
    };

    if (fields.hasOwnProperty(field)) {
        const fieldType = fields[field]?.type;
        if (fieldType === 'integer') {
            data = FormValidation.nameValidation(value?.toString() ?? "");
        } else if (fieldType === 'string') {
            data = FormValidation.nameValidation(value);
        }
    }

    return data;
};