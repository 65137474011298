export const cards = [
    {
        title: 'Employees',
        icon: '',
        url: '/employee/manage'
    },
    {
        title: 'Groups',
        icon: '',
        url: '/groups/manage'
    },
    {
        title: 'News',
        icon: '',
        url: '/news/manage'
    },
    {
        title: 'Alerts',
        icon: '',
        url: '/alerts/manage'
    },
    {
        title: 'Translations',
        icon: '',
        url: '/translation/manage'
    },
    {
        title: 'Documents',
        icon: '',
        url: '/documents/manage'
    },
    {
        title: 'Chats',
        icon: '',
        url: '/chats'
    }
];
