// src/pages/Newss/context/State.tsx
import { FormMode } from "../../../components/common/CommonEnums";
import Upload from "../../../utils/icons/Upload";
import { DocumentState } from "./Interfaces";
import { filterOptions } from "./Utils";

export const initialState: DocumentState = {
    mode: FormMode.CREATE,
    tabs: [
        {
            id: "general",
            title: "General",
            showStatus: true,
            error: false,
            draft: false,
        },
        {
            id: "staff",
            title: "Staff",
            showStatus: true,
            error: false,
            draft: false,
        },
        {
            id: "team",
            title: "Teams/Departments",
            showStatus: true,
            error: false,
            draft: false,
        },
    ],
    general: {
        data: {
            title: "",
            description: "",
            add_to_counter: true,
            send_document_notification: true,
            files: null,
        },
        errors: {
            title: "",
            description: "",
            files: ""
        },
    },
    staff: {
        data: {
            staff: [],
        },
        errors: {
            staff: "",
        },
    },
    team: {
        data: {
            team: [],
        },
        errors: {
            team: "",
        },
    },
    currentTab: "general",
    options: {
        User: [],
        Group: [],
    },
    search: "",
    initialCall: true,
};

export const getFormFields = (state: DocumentState) => {
    const formFields: any = {
        general: [
            {
                0: {
                    name: "title",
                    title: "Title",
                    type: "text",
                    required: true,
                    class: {
                        // mainclass: '',
                        // labelclass: '',
                        // feildclass: '',
                    },
                },
            },
            {
                0: {
                    name: "description",
                    title: "Description",
                    type: "textarea",
                    required: false,
                    class: {
                        // mainclass: '',
                        // feildclass: '',
                    },
                },
            },
            {
                0: {
                    name: "add_to_counter",
                    title: "Add to counter",
                    type: "switch",
                    class: {
                        // mainclass: '',
                         feildclass: 'mb-2',
                    },
                },
                1: {
                    name: "send_document_notification",
                    title: "Send document notification",
                    type: "switch",
                    class: {
                        // mainclass: '',
                        // feildclass: '',
                    },
                }
            },
            {
                0: {
                    name: "files",
                    title: "Upload file",
                    icon: <Upload />,
                    type: "file",
                    required: true,
                    class: {
                         mainclass: 'd-flex align-items-center',
                        // labelclass: '',
                        // buttonclass: '',
                    },
                    accept: ".pdf",
                    message: "Please upload a pdf file",
                    preview: true,
                }
            },
        ],
        staff: [
            {
                0: {
                    name: "staff",
                    title: "Staff",
                    type: "listwithselect",
                    required: false,
                    options: filterOptions(state["options"]?.User, (state?.search)?.trim()),
                    class: {
                        // mainclass: '',
                        // labelclass: '',
                        // buttonclass: '',
                    },
                },
            },
        ],
        team: [
            {
                0: {
                    name: "team",
                    title: "Teams/Departments",
                    type: "listwithselect",
                    required: false,
                    options: filterOptions(state["options"]?.Group, (state?.search)?.trim()),
                    class: {
                        // mainclass: '',
                        // labelclass: '',
                        // buttonclass: '',
                    },
                },
            },
        ],
    };

  return formFields[state.currentTab] || [];
};

export const NewsOptonsPayload = {
    data: [
        {
            model: "Group",
        },
        {
            model: "User",
            filters: { status: [true] },
        }
    ],
};
