import React, { useState, ChangeEvent, FocusEvent } from "react";

interface InputTextAreaFieldProps {
  id?:string
  className?: string;
  value?: string;
  isDisabled?: boolean;
  placeholder?: string;
  customStyle?: React.CSSProperties;
  handleChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  handleClick?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  name?: string;
  rows?: number | undefined;
  error?: string;
}

const style: React.CSSProperties = {};

export default function InputTextAreaField({
    id="",
    className ="form-control shadow-none rounded-2",
    value = "",
    isDisabled = false,
    placeholder = "",
    customStyle = {},
    handleChange = () => {},
    handleClick = () => {},
    name = "",
    rows = 4, // Set a default value for rows
    error = "",
}: InputTextAreaFieldProps) {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <>
        <textarea
            id={id}
            style={{ ...style, ...customStyle }}
            className={className}
            disabled={isDisabled}
            placeholder={isFocused ? "" : placeholder}
            name={name}
            value={value}
            onChange={handleChange}
            onFocus={handleClick}
            onBlur={handleBlur}
            rows={rows}
        />
        {error && <span className="text-danger mt-2">{error}</span>}
        </>
    );
}
