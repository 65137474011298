import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../static/css/login.css";
import LoadingIcon from "../../utils/LoadingIcon";
import InputTextfield from "../../components/atoms/InputTextField";
import LabelField from "../../components/atoms/LabelField";
import ImageField from "../../components/atoms/ImageField";
import Button from "../../components/atoms/Button";
import { APICALL } from "../../services/ApiServices";
import CustomNotify from "../../components/atoms/CustomNotify";
import { Login } from "../../routes/ApiEndpoints";
import { useDispatch } from "react-redux";
import { addUserDetails } from "../../features/auth/AuthSlice";
import Openeye from "../../utils/icons/Openeye";
import Closeeye from "../../utils/icons/Closeeye";

const LoginForm: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [state, setState] = useState({
        data: {
            username: '',
            password: ''
        },
        loading: false,
        passwordtype: 'password'
    });

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setState((prevState: any) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [name]: value
            }
        }));
    };

    const validate = () => {
        let error = '';

        if (state.data.username === '' || state.data.password === '') {
            error = 'Username or password is not filled';
            CustomNotify({
                type: 'error',
                message: error
            });
        }
        return error === '';
    }

    const submitHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setState((prevState: any) => ({ ...prevState, loading: true }));
        event.preventDefault();
        const formData = {
            username: state.data.username,
            password: state.data.password,
            login_type: 'password'
        };
        const validated = validate();

        if (validated) {
            const response = await APICALL.service(Login, "POST", formData);
            if (response.status == 200) {
                let fullname = ((response?.data?.user?.profile?.first_name ?? "") + " " + (response?.data?.user?.profile?.last_name ?? ""));
                dispatch(
                    addUserDetails({
                        userId: response.data.user.id,
                        token: response.data.token,
                        email: response.data.user.email,
                        username: response.data.user.username,
                        fullname: fullname?.trim() != "" ? fullname : response.data.user.username,
                        language: response?.data?.user?.languages[0]?.id,
                        phone: response.data.user.phone,
                        authenticated: true,
                        languageOptions: response?.data?.languageoptions
                    })
                );
                localStorage?.setItem('translations', JSON?.stringify(response?.data?.translations ?? {}));
                navigate('/');
                CustomNotify({
                    type: 'success',
                    message: response.message
                });
            } else {
                CustomNotify({
                    type: 'error',
                    message: response.message
                })
            }
        }
        setState((prevState: any) => ({ ...prevState, loading: false }));
    };

    const togglePassword = () => {
        if (state.passwordtype === 'password') {
            setState((prevState: any) => ({ ...prevState, passwordtype: 'text' }));
            return;
        }
        setState((prevState: any) => ({ ...prevState, passwordtype: 'password' }));
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xxl-7 col-xl-6 col-lg-6 vh-100 ps-0 justify-content-center align-items-center vh-100 ps-0 d-none d-md-none d-lg-block">
                    <ImageField
                        altText="Banner"
                        className="w-100 h-100 img-fluid object-fit-cover"
                        value="/static/images/loginImage.jpg"
                    />
                </div>
                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-8 col-sm-8 m-auto register-form__fields p-xxl-3 p-xl-0 p-lg-0">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-7 m-auto px-xxl-1 p-lg-0">
                            <div className="text-center mb-5">
                                <ImageField
                                    altText="Logo"
                                    className="text-center logo-width"
                                    value="static/images/logo.svg"
                                />
                            </div>
                            <form className="login-form">
                                <h2 className="text-center mb-4 login-title fw-bold">
                                    {" "}
                                    {"Login"}
                                </h2>
                                <div>
                                    <LabelField
                                        title={"Username or email"}
                                        mandatory={true}
                                    // htmlfor="password"
                                    />
                                    <InputTextfield
                                        id="username"
                                        placeholder={"Enter your username"}
                                        name="username"
                                        handleChange={changeHandler}
                                        value={state.data.username}
                                        className="form-control shadow-none rounded-0"
                                    />
                                </div>
                                <div className="my-4">
                                    <LabelField
                                        title={"Password"}
                                        mandatory={true}
                                    // htmlfor="password"
                                    />
                                    <div className="d-flex position-relative">
                                        <InputTextfield
                                            handleChange={changeHandler}
                                            type={state.passwordtype}
                                            value={state.data.password}
                                            id="password"
                                            placeholder={"Enter your password"}
                                            name="password"
                                            className="form-control shadow-none rounded-0"
                                        />
                                        <p
                                            onClick={togglePassword}
                                            className="position-absolute mt-1 me-2 end-0 cursor-pointer"
                                        >
                                            {state.passwordtype === "password" ? (
                                                <Closeeye />
                                            ) : (
                                                <Openeye />
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="text-end">
                                        <Link
                                            to="/forgot-password"
                                            className="link-color"
                                        >
                                            {"Forgot password?"}
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    {!state.loading ? (
                                        <Button
                                            title={"Login"}
                                            type="button"
                                            className="create_button mb-4 w-100 shadow-none rounded-0 text-uppercase"
                                            handleClick={submitHandler}
                                        />
                                    ) : (
                                        <LoadingIcon display="block" classname="dummy" />
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
