import React, { ReactNode, CSSProperties } from 'react';

const style = {};

export interface Props {
    title: ReactNode;
    customStyle?: CSSProperties;
    mandatory?: boolean;
    className?: string;
    htmlforid?: string;
}

const LabelField: React.FC<Props> = ({
    title,
    customStyle = {},
    mandatory = false,
    className = '',
    htmlforid='',
}) => {
    return (
        <>
            <label
                style={{ ...style, ...customStyle }}
                className={`${mandatory ? 'custom_astrick ' + className : className} text-break`}
                htmlFor={htmlforid}
            >
                {title}
            </label>
        </>
    );
};

export default LabelField;
