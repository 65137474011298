import React, { RefObject } from 'react';
import LabelField from '../atoms/LabelField';
import ErrorMessage from '../atoms/ErrorMessage';
import ViewPreviewFile from './ViewPreviewFile';
import Upload from '../../utils/icons/Upload';
import mime from 'mime';
import { t } from '../../services/translation/TranslationUtils';

type Field = {
    name: string;
    required: boolean;
    title: string;
    accept: string;
    message?: string;
    class?: any;
    preview?: boolean;
    hovertext?: any;
    maxSize?: any;
    icon?: any;
};

type Props = {
    field: Field;
    value: any;
    error?: any;
    removeError?: (field: string) => void;
    handleChange: (file: File, field: any) => void;
    inputRef?: RefObject<HTMLInputElement>
};

const FileUploadComponent: React.FC<Props> = ({ field, removeError, handleChange, value, error, inputRef }) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event?.target?.files;
        if (fileList && fileList[0]) {
            const file = fileList[0];
            const validFileExtensions = field.accept.split(',').map((ext: string) => ext.trim());
            const validMimeTypes = validFileExtensions.map((ext: string) => mime.getType(ext));
    
            if (!validMimeTypes.includes(file.type)) {
                alert(`Invalid file type. Please upload a file of type: ${field.accept}`);
                return;
            }
    
            if (file.size > field.maxSize) {
                alert(`File size exceeds the limit of ${field.maxSize / (1024 * 1024)}MB. Please upload a smaller file.`);
                return;
            }

            handleChange(file, field);
        }
    };

    return (
        <div className={field?.class?.mainclass ?? 'create_button'}>
            {field?.title && <LabelField
                title={t(field?.title)}
                mandatory={field?.required}
            />}
            <span title={t(field?.hovertext)}>
            {field?.icon && <LabelField
                htmlforid={field?.name}
                title={field?.icon}
                className='px-2 hoversvg cursor-pointer'
            />}
            </span>
            <input
                name={field?.name}
                className='visually-hidden'
                type="file"
                accept={field?.accept}
                onChange={handleInputChange}
                id={field?.name}
                ref={inputRef}
                onClick={() => removeError && removeError(field?.name)}
            />
            {!value?.file_path && field?.message && (
                <p className='mb-0'>{t(field?.message)}</p>
            )}
            {value?.file_path && field?.preview &&
                (<ViewPreviewFile
                    fileData={value}
                />)
            }
            <ErrorMessage
                errorMessage={error}
            />
        </div>
    );
};

export default FileUploadComponent;
