import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LabelField from '../atoms/LabelField';
import ErrorMessage from '../atoms/ErrorMessage';
// import Emoji from '@ckeditor/ckeditor5-emoji/src/emoji';

interface EditorComponentProps {
    data: string;
    name: string;
    error?: string;
    mandatory?: boolean;
    maindivclass?: string;
    toolbarItems?: string[];
    onReady?: (editor: any) => void;
    onChange?: (event: any, field: any) => void;
    onBlur?: (event: any, editor: any) => void;
    onFocus?: (event: any, editor: any) => void;
}

const EditorComponent: React.FC<EditorComponentProps> = ({
    mandatory = false,
    name = '',
    error = '',
    data,
    maindivclass = '',
    toolbarItems = ['heading', '|', 'bold', 'italic', '|', 'undo', 'redo'],
    onReady,
    onChange,
    onBlur,
    onFocus
}) => {
    return (
        <div className={maindivclass}>
            <LabelField
                title={name}
                mandatory={mandatory}
            />
            <div className={error ? 'border-danger' : ""}>
                <CKEditor
                    editor={ClassicEditor}
                    data={data}
                    config={{
                        // plugins: [Emoji, ...ClassicEditor.builtinPlugins],
                        toolbar: [...toolbarItems, ...ClassicEditor.defaultConfig.toolbar.items]
                    }}
                    onReady={onReady}
                    onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                        onChange && onChange(data, name);
                    }}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
            </div>
            <ErrorMessage
                errorMessage={error}
            />
        </div>
    );
}

export default EditorComponent;
