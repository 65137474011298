import React from "react";
import { renderInput } from "../../../services/form/FormElements";
import { fields } from "./State";
import ViewPreviewFile from "../../molecules/ViewPreviewFile";
import { CreatePostFormProps } from "./Types";

const CreatePostForm: React.FC<CreatePostFormProps> = ({
    removeError,
    handleChange,
    data,
    errors
}) => {
    return (
        <div>
            <div>
                <div className="row gap-3 create_post">
                    {fields.map((fields: any, index: number) => (
                        <div key={index} className="upload">
                            {Object.values(fields)?.map((field: any) => (
                                <div key={field.name} className="">
                                    {renderInput(
                                        removeError,
                                        field,
                                        (e: any) => handleChange(e, field),
                                        data,
                                        errors,
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                    <div className="d-flex">
                        {Object.entries(data).map(([key, value]) => {
                            if (key && ['image', 'video', 'pdf'].includes(key) && value !== null && typeof value === 'object' && 'file_path' in value && value?.file_path && value?.file_path != '') {
                                return (
                                    <div className="flex-1">
                                        <ViewPreviewFile
                                            key={key}
                                            type={key}
                                            fileData={value}
                                            onDeleteFile={(file_path) => removeError(file_path, key)}
                                        />
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatePostForm;
