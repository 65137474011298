import ManageDocumentsOrganism from "../../components/organisms/documents/manage/ManageDocumentsOrganism";

export const ManageDocuments = () => {
    return (
        <>
            <ManageDocumentsOrganism />
        </>
    );
};
export default ManageDocuments;
