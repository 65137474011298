import React, { useRef } from "react";
import FileUploadComponent from "../../../molecules/FileUploadMolecule";
import { fileImportField, manageState } from "../State";
import { importUser } from "../../../../routes/ApiEndpoints";
import { APICALL } from "../../../../services/ApiServices";
import { ImportProps } from "../Types";
import CustomNotify from "../../../atoms/CustomNotify";
import { t } from "../../../../services/translation/TranslationUtils";

const ImportUsers: React.FC<ImportProps > = ({ fetchData }) => {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleImport = async (file: any) => {
		const formData = new FormData();
		formData.append('file', file);

		const response = await APICALL.service(importUser, 'POST', formData);

		if (response?.status === 200) {
			fetchData(manageState.filters);
			CustomNotify({
				type: 'success',
				message: t(response.message)
			});
		} else {
			CustomNotify({
				type: 'error',
				message: t(response.message)
			});
		}
		// Reset the file input after upload
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	}
	return (
		<FileUploadComponent
			field={fileImportField}
			value={''}
			handleChange={handleImport}
			inputRef={fileInputRef}
		/>
	);
};

export default ImportUsers;
