import { isValidPhoneNumber } from "react-phone-number-input";

const FormValidation = {
    nameValidation: function (value: any, length = 255, required = true, fieldtitle = 'The') {
        //For required text fields
        if (((value?.toString()?.trim()) == "" || value == null) && required) {
            return `${fieldtitle} field is required`;
        } else if (value?.toString()?.trim()?.length > length) {
            return `${fieldtitle} field length exceeds ${length} characters`;
        } else {
            return "";
        }
    },
    textValidation: function (value: any, length = 255) {
        //For not required fields
        if (value?.trim()?.length > length) {
            return `The field length exceeds ${length} characters`;
        } else {
            return "";
        }
    },
    zipcodeValidation: function (value: any) {
        //For not required fields
        if (value?.trim()?.length > 20) {
            return "The field length exceeds 20 characters";
        } else {
            return "";
        }
    },
    numberValidation: function (value: any) {
        //only for range atom
        if (value == "") {
            return "This field is required";
        } else if (value > 0 && value <= 100) {
            return "";
        } else {
            return "The field accepts only (1 - 100) numbers";
        }
    },
    isNumberValidation: function (value: any, min: any, max: any) {
        const onlyNumbersRegex = /^[0-9]+$/;

        if (!onlyNumbersRegex.test(value)) {
            return "Invalid input format";
        } else if (Number(value) < Number(min)) {
            return `Minimum value is ${min}`;
        } else if (Number(value) > Number(max)) {
            return `maximum value is ${max}`;
        } else {
            return "";
        }
    },
    isDecimalValidation: function (value: any, min: any, max: any) {
        const decimalNumberRegex = /^\d*\.?\d*$/;

        if (!decimalNumberRegex.test(value)) {
            return "Invalid input format";
        } else if (value < min) {
            return `Minimum value is ${min}`;
        } else if (value > max) {
            return `maximum value is ${max}`;
        } else {
            return "";
        }
    },
    textareaValidation: function (value: any) {
        if (value?.trim() == "") {
            return "This field is required";
        } else {
            return "";
        }
    },
    checkboxValidation: function (value: any) {
        if (value == "") {
            return "This field is required";
        } else {
            return "";
        }
    },
    radioValidation: function (value: any) {
        if (value == "") {
            return "This field is required";
        } else {
            return "";
        }
    },
    emptyValidation: function (value: any) {
        if (value == "" || value == null) {
            return "This field is required";
        } else {
            return "";
        }
    },
    selectValidation: function (value: any) {
        if (value == null || value.length == 0) {
            return "This field is required";
        } else {
            return "";
        }
    },
    multiselectValidation: function (value: any) {
        // For required fields
        if (value.length == 0) {
            return "This field is required";
        } else {
            return "";
        }
    },
    fileValidation: function (value: any) {
        //For not required fields
        if (value == null || value == '') {
            return "This field is required";
        } else {
            return "";
        }
    },
    dateValidation: function (date: string, regex = /^\d{2}\/\d{2}\/\d{4}$/, fieldtitle = 'Date') {
        if (date === '' && !date) {
            return `${fieldtitle} field is empty`;
        } else if (!regex.test(date)) {
            return `${fieldtitle} format is incorrect`
        } else {
            return ""
        }
    },
    isValidHexColor: (color: string) => {
        if (!color) return "";
        const hexColorRegex = /^#[0-9a-fA-F]{6}$/;
        return hexColorRegex.test(color) ? "" : "Invalid hex code"
    },
    hasDuplicates: (array: any) => {
        return new Set(array).size !== array.length;
    },
    phoneValidation: (value: string) => {
        if (value == "" || value == null) {
            return "This field is required";
        } else if (!isValidPhoneNumber(value)) {
            return "Phone number is not valid";
        } else {
            return "";
        }
    },
    emailValidation: (value: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (value && !emailRegex.test(value.toLowerCase())) {
            return "Email is not valid";
        } else {
            return "";
        }
    },
    passwordValidation: (password: string, confirm_password: string) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])/;

        if (password == '' || password == null) {
            return "Password field is required";
        } else if (confirm_password == '' || confirm_password == null) {
            return "Please confirm your password";
        } else if (password?.trim()?.length > 20 || password?.trim()?.length < 8) {
            return "Password length should be between 8 and 20 characters";
        } else if (!passwordRegex.test(password.trim())) {
            return "Please choose a stronger password";
        } else if (password?.trim() != confirm_password?.trim()) {
            return "Password confirmation failed";
        } else {
            return "";
        }
    }
};
export default FormValidation;
