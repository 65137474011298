import React from 'react'

const Openeye = () => {
  return (
    <>
      <svg id="Show" xmlns="http://www.w3.org/2000/svg" width="21.082" height="15.46" viewBox="0 0 21.082 15.46">
        <path id="Path_55859" data-name="Path 55859" d="M19.122,16.311A2.811,2.811,0,1,1,16.311,13.5,2.811,2.811,0,0,1,19.122,16.311Z" transform="translate(-5.77 -8.581)" fill="#00a5ce" />
        <path id="Path_55860" data-name="Path 55860" d="M22.166,13.116a11.729,11.729,0,0,0-10.5-7.491,11.729,11.729,0,0,0-10.5,7.491.7.7,0,0,0,0,.478,11.729,11.729,0,0,0,10.5,7.492,11.729,11.729,0,0,0,10.5-7.491.7.7,0,0,0,0-.478Zm-10.5,4.807a4.568,4.568,0,1,1,4.568-4.568A4.568,4.568,0,0,1,11.666,17.923Z" transform="translate(-1.126 -5.625)" fill="#00a5ce" />
      </svg>

    </>
  )
}

export default Openeye