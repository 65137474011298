import {SEARCH_OPTIONS, SET_ERROR, SET_OPTIONS, SWITCH_TAB, UPDATE_FIELD } from "./Actions";
import { AdminsTab, DescriptionTab, GroupState, TeamsOrDeptsTab, UsersTab } from "./Interfaces";
import { handleIntialStateUpdate, handleSetError, handleUpdateField } from "./Utils";

const GroupReducer = (state: GroupState, action: any): GroupState => {
    const tabState = state[state.currentTab] as DescriptionTab | UsersTab | AdminsTab | TeamsOrDeptsTab;

    switch (action.type) {

        case UPDATE_FIELD:
            return handleUpdateField(tabState, state, action);

        case SET_ERROR:
            return handleSetError(state, action);

        case SWITCH_TAB:
                return {
                    ...state,
                    currentTab: action.value,
                    search: "",
                    tabs: state.tabs.map(tab => 
                        tab?.id === action?.value ? { ...tab, error: false } : tab
                    ),
                };
    
        case SEARCH_OPTIONS:
            return {
                ...state,
                search: action.value
            };

        case SET_OPTIONS:            
            if (action?.value?.type && action?.value?.type == 'create') {
                return {
                    ...state,
                    options: action.value?.value,
                    initialCall: false,
                    description: {
                        ...state?.description,
                        data: {
                            ...state?.description?.data,
                            group_type_id: (
                                (state['description']?.data?.group_type_id) ? 
                                    state['description']?.data?.group_type_id
                                : 
                                    action.value?.value?.GroupType?.[0]?.value
                                )
                        }
                    }
                };
            }
            return handleIntialStateUpdate(state, action);

        default:
            return state;
    }
};

export default GroupReducer;