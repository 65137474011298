import React, { useEffect, useState } from 'react'
import InputTextfield from '../atoms/InputTextField'
import Button from '../atoms/Button';
import { t } from '../../services/translation/TranslationUtils';
import ErrorMessage from '../atoms/ErrorMessage';
import FormValidation from '../../services/formvalidation/Validation';
import ActionButtonGroup from './ActionButtonGroup';
interface Props {
    oldstring: string;
    handleSave: (value: any) => void;
    handleCancel: () => void;
}

const TranslationEditPopup: React.FC<Props> = ({ oldstring, handleSave, handleCancel }) => {

    const [state, setState] = useState({
        string: oldstring,
        error: ""
    });

    const handleSubmit = () => {
        let error = FormValidation?.nameValidation(state?.string);
        if (error) {
            setState((prev) => ({ ...prev, error: error }));
        } else {
            handleSave(state?.string);
        }
    }
    return (
        <div className='px-3'>
            <InputTextfield
                name='string'
                value={state?.string}
                className={`border rounded text-gray w-100 px-3 form-control ${state?.error ? "border-danger" : ""}`}
                handleChange={(e) => setState((prev) => ({ ...prev, string: e?.target?.value }))}
            />
            <ErrorMessage
                errorMessage={state?.error}
            />
            <div className="mt-4">
                <ActionButtonGroup
                    saveAndNextTitle={t("Save")}
                    handleSaveAndNextClick={handleSubmit}
                    backTitle={t("Cancel")}
                    handleBackClick={handleCancel}
                />
            </div>
        </div>
    )
}

export default TranslationEditPopup