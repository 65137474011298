import React, { ChangeEvent } from 'react';
import Button from '../../../atoms/Button';
import { t } from '../../../../services/translation/TranslationUtils';
import InputTextfield from '../../../atoms/InputTextField';
import { ManageNewsFiltersProps } from '../../../../pages/news/context/Interfaces';

const ManageNewsFilters: React.FC<ManageNewsFiltersProps> = ({
    value,
    handleSearch,
    handleFilter,
    handleClear
}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const search = e.target.value;
        handleFilter(search);
    }

    return (
        <div className='d-flex'>
            <div className='flex-1 me-3'>
                <InputTextfield
                    placeholder={t("Search by title")}
                    className='w-100 form-control'
                    value={value}
                    handleChange={handleChange}
                />
            </div>
            <div className=''>
                <Button
                    className='search_btn me-3'
                    title={t('Search')}
                    handleClick={handleSearch}
                />
                <Button
                    className='reset_btn'
                    title={t('Clear')}
                    handleClick={handleClear}
                />
            </div>
        </div>
    )
}

export default ManageNewsFilters;
