import ImageUpload from "../../../utils/icons/ImageUpload";
import { Surver } from "../../../utils/icons/Surver";
import { UpladPdf } from "../../../utils/icons/UpladPdf";
import { VideoUpload } from "../../../utils/icons/VideoUpload";

export const initialstate = {
    form: {
        news_id: null,
        body: '',
        image: {
            file: [],
            file_name: [],
            file_path: []
        },
        video: {
            file: [],
            file_name: [],
            file_path: []
        },
        pdf: {
            file: [],
            file_name: [],
            file_path: []
        },
        survey: {
            ismulti: false,
            max_number_of_answers: 1,
            min_number_of_answers: 1,
            vote_until: "",
            survey_options: [],
            survey_permissions: []
        }
    },
    errors: {
        body: '',
        image: '',
        video: '',
        pdf: '',
        survey: '',
    },
    survey_popup: false,
    user_options: []
}
export const NewsOptonsPayload = {
    data: [
        {
            model: "User",
            filters: { status: [true] },
        }
    ],
};

export const fields = [
    {
        0: {
            type: 'editor',
            name: 'body',
            required: true,
        }
    },
    {
        0: {
            type: 'file',
            name: 'image',
            required: false,
            icon: <ImageUpload />,
            hovertext: 'Upload image',
            accept: '.png, .jpg, .jpeg, gif',
            message: "Please upload an image file",
            preview: false,
            maxSize: 2 * 1024 * 1024,
            class: {
                mainclass: ""
            }
        },
        1: {
            type: 'file',
            name: 'video',
            required: false,
            icon: <VideoUpload />,
            hovertext: 'Upload video',
            accept: '.mp4, .avi, .mov, .webm',
            message: "Please upload a video file",
            preview: false,
            maxSize: 1 * 1024 * 1024 * 1024,
            class: {
                mainclass: ""
            }
        },
        2: {
            type: 'file',
            name: 'pdf',
            required: false,
            icon: <UpladPdf />,
            hovertext: 'Upload pdf',
            accept: '.pdf',
            message: "Please upload a PDF file",
            preview: false,
            maxSize: 10 * 1024 * 1024,
            class: {
                mainclass: ""
            }
        },
        3: {
            type: 'svg',
            name: 'survey',
            svg: <Surver />,
            required: false,
            hovertext: 'Create survey',
        },
    },
];

