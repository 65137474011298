import React from 'react';
import { renderInput } from '../../../../services/form/FormElements';
import { TabComponentProps } from '../../../../pages/documents/context/Interfaces';

const GeneralTab: React.FC<TabComponentProps> = ({ 
    state, 
    dispatch, 
    handleChange, 
    formConfig,
    removeError,
}) => {
    return (
        <div>
            <div className="row gap-3">
                {formConfig.map((fields: any, index: number) => (
                    <div key={index} className="">
                        {Object.values(fields)?.map((field: any) => (                            
                            <div key={field?.name} className="">
                                {renderInput(
                                    removeError,
                                    field,
                                    (e: any) => handleChange(e, field),
                                    state[state?.currentTab]?.data,
                                    state[state?.currentTab]?.errors
                                )}
                            </div>
                        ))}
                    </div>
                ))}
                <div>
                    
                </div>
            </div>
        </div>
    );
};

export default GeneralTab;
