
export const tableHeaders = [
    { name: 'Title', width: 20 },
    { name: 'Description', width: 40 },
    { name: 'Created by', width: 15 },
    { name: 'Activity', width: 10 }
];

export const tableValues = [
    'title',
    'description',
    'created_by',
    'activity'
];

export const manageState = {
    filters: {
        status: [],
        search: "",
        team: [],
        staff: []
    },
    popup: {
        archivepopup: {
            id: null,
            status: null,
            showpopup: false
        }
    },
    options: {} as any,
    data: [] as any,
    manage: true,
    initialCall: true,
    current_page: 1,
    limit: 10,
    totalPages: 0,
    offset: 0,
    totalCount: 0,
    refresh: true
};

export const filterValues = (options: any) => {    
    let row = [
        {
            name: 'team',
            type: 'multi-select-without-label',
            placeholder: 'Select group',
            value: [],
            options: options?.Group,
        },
        {
            name: 'staff',
            type: 'multi-select-without-label',
            placeholder: 'Select user',
            value: [],
            options: options?.User,
        },
        {
            name: 'status',
            type: 'multi-select-without-label',
            placeholder: 'Select status',
            value: [],
            options: [
                { value: 'true', label: "Active" },
                { value: 'false', label: "Inactive" },
            ],
        },
        {
            name: 'search',
            class: {
                mainclass: 'h-100',
                feildclass: 'h-100'
            },
            type: 'text',
            placeholder: 'Search title',
            value: "",
            label: true
        },
    ]
    return row;
}
