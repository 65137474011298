// src/pages/groups/Index.tsx
import GroupForm from '../../components/organisms/groups/CreateGroupOrganism';
import { GroupProvider } from './context/GroupContext';

const CreateGroup = () => {
    return (
        <GroupProvider>
            <GroupForm />
        </GroupProvider>
    );
};

export default CreateGroup;
