interface Props {
    value: string;
    className?: string;
}

const VideoPlayer: React.FC<Props> = ({
    value,
    className = ''
}) => {
    return (
        <video className={className} controls height={400}>
            <source src={value} type="video/mp4" />
            {'Your browser does not support the video tag.'}
        </video>
    );
}

export default VideoPlayer;