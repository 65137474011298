import React from "react";

interface CardProps {
    title: string;
    styleClass?: string;
    cardbodyCLass?: string;
    handleClick?: () => void;
}

const Card: React.FC<CardProps> = ({
    title,
    styleClass = '',
    cardbodyCLass = '',
    handleClick
}) => {
    return (
        <div onClick={handleClick} className={`card cursor-pointer ${styleClass}`}>
            <div className={`card-body ${cardbodyCLass}`} >
                <div>{title}</div>
            </div>
        </div>
    )
}
export default Card;
