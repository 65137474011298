import React from 'react';
import { renderInput } from '../../../../services/form/FormElements';
import InputTextfield from '../../../atoms/InputTextField';
import { TabComponentProps } from '../../../../pages/documents/context/Interfaces';

const CommonTab: React.FC<TabComponentProps> = ({
    state,
    dispatch,
    handleChange,
    formConfig,
    removeError,
    handleSearch
}) => {

    return (
        <div className='w-100'>
            {handleSearch &&
                <InputTextfield
                    name='search'
                    handleChange={(e) => handleSearch(e)}
                    placeholder='Search'
                    value={state.search}
                    className='form-control shadow-none ms-auto w-25'
                />
            }
            <div className="mt-3">
                {formConfig.map((fields: any, index: number) => (
                    <div key={index} className="">
                        {Object.values(fields)?.map((field: any) => (
                            <div key={field.name} className="">
                                {renderInput(
                                    removeError,
                                    field,
                                    (e: any) => handleChange(e, field),
                                    state[state?.currentTab]?.data,
                                    state[state?.currentTab]?.errors,
                                    state?.search
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CommonTab;
