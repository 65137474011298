import React, { useEffect, useState } from 'react';
import Button from '../../../atoms/Button';
import { t } from '../../../../services/translation/TranslationUtils';
import TableStructure from '../../../atoms/TableStructure';
import { manageState, tableHeaders, tableValues } from '../../../../pages/news/context/State';
import { APICALL } from '../../../../services/ApiServices';
import { CREATE_NEWS, MANAGE_NEWS } from '../../../../routes/ApiEndpoints';
import ManageNewsFilters from './ManageNewsFilters';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../molecules/Paginations';
import Popup from '../../../molecules/Popup';
import ManageUserTabs from '../../users/manage/ManageUserTabs';

const ManageNewsOrganism: React.FC = () => {
    const [state, setState] = useState(manageState);
    const navigate = useNavigate();

    useEffect(() => {
        fetchData(manageState.postData);
    }, []);

    const fetchData = async (postData: any) => {
        const response = await APICALL.service(MANAGE_NEWS, 'POST', postData);

        if (response.status === 200) {
            setState((prevState: any) => ({
                ...prevState,
                postData: {
                    ...prevState.postData,
                    total_pages: response.total_pages
                },
                data: response.data
            }));
        }
    }

    const handleFilter = (search: string) => {
        setState((prevState: any) => ({
            ...prevState,
            postData: {
                ...prevState.postData,
                search: search
            }
        }));
    }

    const handleView = (value: any) => {
        navigate(`/news/view?id=${value?.id}`);
    }

    const handleEdit = (value: any) => {
        navigate(`/news/create?edit=${value?.id}`);
    }

    const handlePaginationClick = (pageNumber: number) => {
        let postData = {
            ...state.postData,
            current_page: pageNumber
        }
        setState((prevState: any) => ({
            ...prevState,
            postData: postData
        }));
        fetchData(postData);
    };

    const handleClear = () => {
        setState((prevState: any) => ({
            ...prevState,
            postData: manageState.postData
        }));
        fetchData(manageState.postData);
    }

    const handleArchivePopup = (value: any) => {
        setState((prevState: any) => ({
            ...prevState,
            popup: value
        }));
    }

    const handleArchive = async (value: any) => {
        let postData = {
            status: !value.activity,
            status_change: true
        }
        const response = await APICALL.service(CREATE_NEWS + '/' + value.id, 'POST', postData);

        if (response.status === 200) {
            setState((prevState: any) => ({
                ...prevState,
                popup: false
            }));
            fetchData(state.postData);
        }
    }

    const handleTabsClick = (tab: string) => {
        let status = null;
        if (tab !== 'all') {
            status = tab === 'active';
        }
        let postData = {
            ...state.postData,
            status: status
        }
        setState((prevState: any) => ({
            ...prevState,
            postData: postData
        }));
        fetchData(postData);
    };

    return (
        <>
            <h2> {t("Manage news")} </h2>
            <div className="d-flex justify-content-end mb-3">
                <Button
                    className={`create_button`}
                    handleClick={() => navigate('/news/create')}
                    title={'+ ' + t('Create news')}
                />
            </div>
            <ManageNewsFilters
                value={state.postData.search}
                handleFilter={handleFilter}
                handleSearch={() => fetchData(state.postData)}
                handleClear={handleClear}
            />
            <ManageUserTabs
                handleClick={handleTabsClick}
                status={state?.postData?.status}
            />
            <TableStructure
                isAction
                data={state.data}
                headers={tableHeaders}
                values={tableValues}
                handleEdit={handleEdit}
                handleArchive={handleArchivePopup}
                handleView={handleView}
            />
            {state?.postData?.total_pages > 1 &&
                <Pagination
                    currentPage={state?.postData?.current_page}
                    totalPages={state?.postData?.total_pages}
                    handlePaginationClick={handlePaginationClick}
                />
            }
            {state?.popup && <Popup
                body={t('Are you sure, you want to change the activity') + '?'}
                yestext={t('Yes')}
                notext={t('No')}
                submit={() => handleArchive(state.popup)}
                cancel={() => setState((prevState: any) => ({
                    ...prevState,
                    popup: false
                }))}
            />}
        </>
    )
}

export default ManageNewsOrganism;
