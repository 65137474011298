import React from 'react'

export const UpladPdf = () => {
  return (

    <svg version="1.0"
      width="35" height="35" viewBox="0 0 50.000000 50.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M60 250 l0 -250 190 0 190 0 0 177 0 178 -72 72 -72 73 -118 0 -118
0 0 -250z m295 160 l59 -60 -62 0 -62 0 0 60 c0 33 1 60 3 60 1 0 29 -27 62
-60z m-147 -187 c2 -14 -4 -26 -17 -33 -12 -6 -21 -20 -21 -30 0 -11 -4 -20
-10 -20 -5 0 -10 25 -10 56 0 54 0 55 28 52 19 -2 28 -9 30 -25z m78 1 c10
-38 -7 -78 -34 -82 -20 -3 -22 0 -22 52 0 53 1 56 25 56 18 0 26 -7 31 -26z
m52 19 c-10 -2 -18 -11 -18 -18 0 -8 8 -19 18 -24 15 -10 16 -10 0 -11 -10 0
-18 -10 -21 -27 -2 -16 -5 -2 -6 30 -1 53 0 57 22 56 16 -1 18 -2 5 -6z"/>
        <path d="M170 220 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0
-10 -9 -10 -20z"/>
        <path d="M240 200 c0 -29 4 -40 13 -37 6 2 12 19 12 37 0 18 -6 35 -12 38 -9
2 -13 -9 -13 -38z"/>
      </g>
    </svg>

  )
}
