import React, { ChangeEvent, useEffect, useState } from "react";
import { APICALL } from "../../../services/ApiServices";
import { initialstate } from "./State";
import CreatePostForm from "./CreatPostForm";
import { CREATE_POSTS, MANAGE_NEWS } from "../../../routes/ApiEndpoints";
import Popup from "../../molecules/Popup";
import AddOptionsMolecule from "../../molecules/AddOptionsMolecule";
import { useNavigate } from "react-router-dom";
import ActionButtonGroup from "../../molecules/ActionButtonGroup";
import CustomNotify from "../../atoms/CustomNotify";
import FormValidation from "../../../services/formvalidation/Validation";
import { Files } from "../../common/CommonInterfaces";
import S3Services from "../../../utils/S3Bucket/S3Services";
import { t } from "../../../services/translation/TranslationUtils";

interface CreatePostOrganismProps {
    newsid: number;
    postid?: number | null;
    handleSave: () => void;
    options: any,
  }
  
  const CreatePostOrganism: React.FC<CreatePostOrganismProps> = ({ newsid, options, handleSave, postid = null }) => {
    // const searchParams = new URLSearchParams(window.location.search);
    // const newsid = searchParams.get("nid");
    // const postid = searchParams.get("pid");
    const [state, setState] = useState(initialstate);
    const navigate = useNavigate();
    useEffect(() => {
        if (newsid != null) {
            setState((prev: any) => ({
                ...prev, 
                form: {
                    ...prev.form,
                    news_id: Number(newsid) ?? newsid,
                },
                user_options: options
            }));
            // fetchData();
        }
    }, [newsid != null]);

    const fetchData = async () => {
        try {
            const postdata = {
                posts: true
            }
            const response = await APICALL.service((MANAGE_NEWS + '/' + newsid), 'POST', postdata);

            if ([200, 201].includes(response?.status)) {                               
                setState((prevState: any) => ({
                    ...prevState,
                    form: {
                        ...prevState.form,
                        news_id: Number(newsid) ?? newsid,
                    },
                    user_options: response?.data?.options ?? [],
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    const removeError = (fieldName: any, key?: string) => {
        if (key) {
            const updatedForm: any = { ...state.form };
            // Find the index of the item to remove
            const index = updatedForm?.[key]?.file_path.indexOf(fieldName);
            console.log(updatedForm?.[key], index,fieldName);
            
            if (index !== -1) {
                // Create a copy of the state and remove the item
                updatedForm[key].file.splice(index, 1);
                updatedForm[key].file_path.splice(index, 1);
                updatedForm[key].file_name.splice(index, 1);
                
                // Update the state with the modified form data
                setState((prevState: any) => ({
                    ...prevState,
                    form: updatedForm,
                }));
            }
        } else {
            setState((prevState: any) => ({ ...prevState, errors: { ...prevState?.errors, [fieldName]: ""}}));
        }
    };

    const handleFileOperation = (file: File, field: any) => {
        if (file) {
            const newFile: Files = {
                file,
                file_path: file && URL.createObjectURL(file),
                file_name: file?.name,
            };
            
            setState((prevState: any) => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    [field.name]:{
                        file: [...prevState.form[field.name].file, newFile.file],
                        file_path: [...prevState.form[field.name].file_path, newFile.file_path],
                        file_name: [...prevState.form[field.name].file_name, newFile.file_name],
                    }
                }
            }));
        }
    };

    const handleChange = (e: any, field: any) => {
        console.log(e, field);
                               
        if (field?.type === 'file') {            
            handleFileOperation(e, field);
        } else {
            let value = e?.target?.value ?? e;
            if (field?.type === 'svg') {
                setState((prevState: any) => ({
                    ...prevState,
                    survey_popup: !state?.survey_popup
                }));
            } else {
                setState((prevState: any) => ({
                    ...prevState,
                    form: {
                        ...prevState.form,
                        [field?.name]: value,
                    },
                    survey_popup: false
                }));
            }
        }
    };
    
    const handleSubmit = async () => {
        let error = FormValidation?.textareaValidation(state?.form?.body?.trim());
        if (!error) {
            try {
                let accept = ['image', 'video', 'pdf'];
                const object: any = {};          
                const uploadPromises: Promise<void>[] = [];
                const loadingIcon = document.getElementById("loading-div-id");
                if (loadingIcon) loadingIcon.style.display = "block";
                Object.entries(state?.form).forEach(([key, value]) => {
                    if (key && accept?.includes(key) && value !== null && typeof value === 'object' && 'file' in value && value?.file?.length) {
                        const uploadPromise = S3Services.uploadToS3(value.file.map((fileObj: any) => fileObj), ('posts/' + key + '/'))
                            .then((uploadedFiles) => {
                                object[key] = uploadedFiles;
                            });
                        uploadPromises.push(uploadPromise);
                    }
                });
                await Promise.all(uploadPromises);
                if (loadingIcon) loadingIcon.style.display = "none";
                // Append other data
                const data = {
                    news_id: state?.form?.news_id,
                    body: state?.form?.body,
                    entities: {
                        image: {
                            file_path: (object?.image && object?.image?.length) ? object?.image : state?.form?.image?.file_path,
                            file_name: state?.form?.image?.file_name
                        },
                        video: {
                            file_path: (object?.video && object?.video?.length) ? object?.video : state?.form?.video?.file_path,
                            file_name: state?.form?.video?.file_name
                        },
                        pdf: {
                            file_path: (object?.pdf && object?.pdf?.length) ? object?.pdf : state?.form?.pdf?.file_name,
                            file_name: state?.form?.pdf?.file_name
                        },
                        survey: state?.form?.survey
                    }
                };
                const response = await APICALL.service(CREATE_POSTS + (postid ? '/' + postid : ''), 'POST', data);
                CustomNotify({ type: response.status === 200 ? "success" : "error", message: response.message });
                if (response.status === 200) {
                    handleSave();
                }
            } catch (error) {
                CustomNotify({ type: "error", message: "Error while creating news" });
            }
        } else {
            setState((prevState: any) => ({ ...prevState, errors: { ...prevState?.errors, body: error } }));
        }
    };
    

    return (
        (state && 
            <>
                <CreatePostForm
                    data={state?.form}
                    errors={state?.errors}
                    handleChange={handleChange}
                    removeError={removeError}
                />
                <ActionButtonGroup
                    maindivcss="navigation-buttons"
                    backTitle={t('Back')}
                    saveAndNextTitle={t('Post')}
                    handleBackClick={() => navigate('/')}
                    handleSaveAndNextClick={handleSubmit}
                />
                {state?.survey_popup && (
                    <Popup
                        title={t("Create survey")}
                        body={<AddOptionsMolecule 
                            handleSubmit={(data: any) => handleChange(data, {name: 'survey'})}
                            survey={state?.form?.survey}
                            state={state}
                        />}
                        bodyclassName='survey_popup_body'
                        cancel={() => setState((prev) => ({ ...prev, survey_popup: false }))}
                    />
                )}
            </>
        )
    );
};

export default CreatePostOrganism;
