import React from "react";
import { t } from "../../services/translation/TranslationUtils";
import Card from "../../components/atoms/Card";
import { cards } from "./Constants";
import { useNavigate } from "react-router-dom";

const Dashboard: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="dashboard_cards p-5">
            {cards.map((card: any) => (
                <Card
                    title={t(card.title)}
                    cardbodyCLass="d-flex align-items-center justify-content-center"
                    handleClick={() => navigate(card.url)}
                />
            ))}
        </div>
    );
};

export default Dashboard;
