import ManageAlertOrganism from "../../components/organisms/alerts/ManageAlertOrganism";

export const ManageAlert = () => {
    return (
        <>
            <ManageAlertOrganism />
        </>
    );
};
export default ManageAlert;
