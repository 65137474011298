import React, { useState, ChangeEvent, FocusEvent, MouseEvent } from "react";
import LabelField from "./LabelField";
import ImageField from "./ImageField";

const style = {};

interface Props {
    path?: string;
    title?: String;
    handleClick?: (value: any) => void;
    classname1?: string;
    classname2?: string;
    hoverText?: string;
}

const ImageWithTitle: React.FC<Props> = ({ path, title, handleClick, classname1, classname2, hoverText = '' }) => {

    return (
        <span className={`${classname1}`} title={hoverText} onClick={handleClick}>
           <ImageField
                altText="Profile picture"
                height="35"
                width="35"
                className="text-center me-2"
                value="../static/images/profile.png"
            />
            {title && <LabelField title={title} className={classname2} />}
        </span>
    );
};

export default ImageWithTitle;
