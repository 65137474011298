import { t } from "../../../../services/translation/TranslationUtils";
import Button from "../../../atoms/Button";
import { ManageUserTabProps } from "../Types";

const ManageUserTabs: React.FC<ManageUserTabProps> = ({
    handleClick,
    status
}) => {

    return (
        <div className="my-3">
            <Button
                className={`border-0 bg-transparent tab-btn me-4 ${status === null ? 'active-tab': ''}`}
                title={t('All')}
                handleClick={() => handleClick('all')}
            />
            <Button
                className={`border-0 bg-transparent tab-btn me-4 ${status ? 'active-tab': ''}`}
                title={t('Active')}
                handleClick={() => handleClick('active')}
            />
            <Button
                className={`border-0 bg-transparent tab-btn me-4 ${status == false ? 'active-tab': ''}`}
                title={t('Inactive')}
                handleClick={() => handleClick('inactive')}
            />
        </div>
    );
};

export default ManageUserTabs;
