import React from 'react';
import Button from '../atoms/Button';
import { t } from '../../services/translation/TranslationUtils';
import Last from '../../utils/icons/Last';
import Next from '../../utils/icons/Next';
import Previous from '../../utils/icons/Previous';
import First from '../../utils/icons/First';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  handlePaginationClick: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  handlePaginationClick
}) => {
  const generatePaginationNumbers = (): JSX.Element[] => {
    const paginationNumbers: JSX.Element[] = [];

    // First page
    paginationNumbers.push(
      <Button
        key={"First"}
        hoverText={t("First")}
        handleClick={() => handlePaginationClick(1)}
        className="bg-transparent border-0 px-2 py-2 shadow-none"
        disabled={currentPage <= 1}
      >
        <First />
      </Button>
    );

    // Previous page
    paginationNumbers.push(
      <Button
        key={"Previous"}
        hoverText={t("Previous")}
        handleClick={() => handlePaginationClick(currentPage - 1)}
        className="bg-transparent border-0 px-2 py-2 shadow-none"
        disabled={currentPage <= 1}
      >
        <Previous />
      </Button>
    );

    // Left side page
    if (currentPage > 1) {
      paginationNumbers.push(
        <Button
          key={"Current" + (currentPage - 1)}
          title={(currentPage - 1).toString()}
          handleClick={() => handlePaginationClick(currentPage - 1)}
          className="bg-transparent border-0 px-2 py-2 shadow-none page-num"
        />
      );
    }

    // Current page
    paginationNumbers.push(
      <Button
        key={"Current" + currentPage}
        title={(currentPage).toString()}
        handleClick={() => handlePaginationClick(currentPage)}
        className="active border-0 px-2 py-2 shadow-none page-num"
      />
    );

    // Right side page
    if (currentPage < totalPages) {
      paginationNumbers.push(
        <Button
          key={"Current" + (currentPage + 1)}
          title={(currentPage + 1).toString()}
          handleClick={() => handlePaginationClick(currentPage + 1)}
          className="bg-transparent border-0 px-2 py-2 shadow-none page-num"
        />
      );
    }

    // Next page
    paginationNumbers.push(
      <Button
        key={"Next"}
        hoverText={t("Next")}
        handleClick={() => handlePaginationClick(currentPage + 1)}
        className="bg-transparent border-0 px-2 py-2 shadow-none"
        disabled={currentPage >= totalPages}
      >
        <Next />
      </Button>
    );

    // Last page
    paginationNumbers.push(
      <Button
        key={"Last"}
        hoverText={t("Last")}
        handleClick={() => handlePaginationClick(totalPages)}
        className="bg-transparent border-0 px-2 py-2 shadow-none"
        disabled={currentPage >= totalPages}
      >
        <Last />
      </Button>
    );

    return paginationNumbers;
  };

  return (
    <div className="pagination justify-content-center">
      {generatePaginationNumbers()}
    </div>
  );
};

export default Pagination;
