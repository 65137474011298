import React from 'react';

const Camera: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
      <g id="Camera" transform="translate(-57.188 -64.734)">
        <circle id="Ellipse_208" data-name="Ellipse 208" cx="11.5" cy="11.5" r="11.5" transform="translate(57.188 64.734)" fill="#00a5ce" />
        <g id="Group_48170" data-name="Group 48170" transform="translate(62.789 71.515)">
          <g id="Group_27798" data-name="Group 27798" transform="translate(0 0)" clipPath="url(#clipPath)">
            <path id="Path_55800" data-name="Path 55800" d="M3.5,5.445a2.4,2.4,0,1,0,2.4-2.36,2.379,2.379,0,0,0-2.4,2.36m7.56-3.993H8.479C8.3.726,8.113,0,7.375,0H4.425C3.687,0,3.5.726,3.319,1.452H.737A.734.734,0,0,0,0,2.179V8.713a.733.733,0,0,0,.737.726H11.062a.734.734,0,0,0,.736-.726V2.179a.734.734,0,0,0-.736-.727M5.9,8.668A3.222,3.222,0,1,1,9.17,5.446,3.246,3.246,0,0,1,5.9,8.668m5.163-5.037H9.586V2.9h1.477v.726Z" transform="translate(0 0)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Camera;