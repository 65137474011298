import React from "react";
import { MediaProps } from "../../../../pages/news/context/Interfaces";
import ImageField from "../../../atoms/ImageField";
import VideoPlayer from "../../../atoms/VideoPlayer";
import '../CreateNewsOrganism.css';

const ShowMedia: React.FC<MediaProps> = ({
    images,
    videos
}) => {
    return (
        <div className="post-image-grid gap-3 mb-3">
            {images?.length > 0 && images.map((image: any, index: number) => (
                <div className="post-image" key={`image-${index}`}>
                    <ImageField
                        className="w-100 h-100 object-fit-contain"
                        value={image.file_path}
                    />
                </div>
            ))}
            {videos?.length > 0 && videos.map((video: any, index: number) => (
                <div className="post-image" key={`video-${index}`}>
                    <VideoPlayer
                        className="w-100 h-100 object-fit-contain"
                        value={video.file_path}
                    />
                </div>
            ))}
        </div>
    );
}

export default ShowMedia;
