export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const CHATS_URL = process.env.REACT_APP_CHATS_URL

export const API_URL = BACKEND_URL + 'api';

export const Login = API_URL + '/login';
export const Logout = API_URL + '/logout';
export const generateOtp = API_URL + '/generate-otp';
export const forgotPassword = API_URL + '/forgot-password';

/******** Groups starts */
export const CREATE_GROUP = API_URL + '/group/create';
export const MANAGE_GROUP = API_URL + '/group/get';
/******** Groups ends */

export const fetchOptions = API_URL + '/options/fetch';

/** users start */
export const saveTranslation = API_URL + '/strings/create';
export const manageTranslation = API_URL + '/strings/fetch';
/** users end */

/******** News starts */
export const CREATE_NEWS = API_URL + '/news/create';
export const MANAGE_NEWS = API_URL + '/news/get';
/******** News ends */

/******** Posts starts */
export const CREATE_POSTS = API_URL + '/post/create';
export const MANAGE_POSTS = API_URL + '/post/get';
export const ACTION_POSTS = API_URL + '/news-post/action';
export const GET_POST = API_URL + '/post/get';
/******** Posts ends */

/** users start */
export const createUser = API_URL + '/user/create';
export const manageUser = API_URL + '/user/get';
export const importUser = API_URL + '/user/import';
/** users end */

/** documents start */
export const createDocuments = API_URL + '/my-docs/create';
export const manageDocuments = API_URL + '/my-docs/get';
/** documents end */

/** Alerts start */
export const createAlerts = API_URL + '/alerts/create';
export const manageAlerts = API_URL + '/alerts/manage';
/** Alerts end */