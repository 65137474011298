import { SWITCH_TAB } from "../pages/groups/context/Actions";

export const handleTabChange = (
    state: any,
    dispatch: any,
    direction: 'next' | 'previous' | 'switch',
    tabIndex?: number
) => {
    const currentIndex = state?.tabs.findIndex((tab: any) => tab?.id === state?.currentTab);

    switch (direction) {
        case 'next':
            if (currentIndex < state.tabs.length - 1) {
                dispatch({ type: SWITCH_TAB, value: state.tabs[currentIndex + 1]?.id });
            }
            break;
        case 'previous':
            if (currentIndex > 0) {
                dispatch({ type: SWITCH_TAB, value: state.tabs[currentIndex - 1]?.id });
            }
            break;
        case 'switch':
            if (tabIndex !== undefined && tabIndex >= 0 && tabIndex < state.tabs.length) {
                dispatch({ type: SWITCH_TAB, value: state.tabs[tabIndex].id });
            }
            break;
        default:
            break;
    }
};

export const changeButtonTitles = (state: any, button: 'back' | 'saveandnext', titles = ['Save', 'Next']): string => {
    const currentIndex = state.tabs.findIndex((tab: any) => tab.id === state.currentTab);
    const isFirstTab = currentIndex === 0;
    const isLastTab = currentIndex === state.tabs.length - 1;

    switch (button) {
        case 'back':
            return isFirstTab ? titles[0] : titles[1];
        case 'saveandnext':
            return isLastTab ? titles[0] : titles[1];
        default:
            return '';
    }
};

export const dynamicSubmitAndBackFunction = (state: any, button: string) => {
    const currentIndex = state.tabs.findIndex((tab: any) => tab.id === state.currentTab);
    const isLastTab = currentIndex === state.tabs.length - 1;
    if (button == 'save') {
        return isLastTab ? true : false;
    }
    const isFirstTab = currentIndex === 0;
    return isFirstTab ? true : false;
}