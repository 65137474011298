import React, { ChangeEvent, useState } from "react";
import { passwordState } from "../State";
import ChangePasswordForm from "./ChangePasswordForm";
import { APICALL } from "../../../../services/ApiServices";
import { forgotPassword } from "../../../../routes/ApiEndpoints";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../../features/auth/AuthSlice";
import { useNavigate } from "react-router-dom";
import CustomNotify from "../../../atoms/CustomNotify";

const ChangePasswordOrganism: React.FC = () => {
    const [state, setState] = useState(passwordState);
    const user = useSelector(selectAuth);
    const navigate = useNavigate();

    const changeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        let { name, value } = event.target;
        setState((prevState: any) => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: value
            }
        }));
    };

    const togglePassword = (field: string) => {
        setState((prevState: any) => {
            const newType = prevState.type[field] === 'password' ? 'text' : 'password';
            return {
                ...prevState,
                type: {
                    ...prevState.type,
                    [field]: newType
                }
            };
        });
    };

    const removeError = (fieldName: any) => {
        if (passwordState.errors.hasOwnProperty(fieldName)) {
            setState((prevState: any) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    [fieldName]: ""
                }
            }));
        }
    };

    const submitHandler = async (error: any, valid: boolean) => {
        if (valid) {
            let postData = {
                password: state.form.new_password,
                old_password: state.form.current_password,
                user_id: user?.userId
            }
            const response = await APICALL.service(forgotPassword, 'POST', postData);

            if (response?.status === 200) {
                navigate('/');
                CustomNotify({
                    type: 'success',
                    message: response?.message
                });
            } else {
                CustomNotify({
                    type: 'error',
                    message: response?.message
                })
            }
        } else {
            setState((prevState: any) => ({
                ...prevState,
                errors: error
            }));
        }
    }

    return (
        <ChangePasswordForm
            state={state}
            togglePassword={togglePassword}
            changeHandler={changeHandler}
            submitHandler={submitHandler}
            removeError={removeError}
        />
    );
};

export default ChangePasswordOrganism;
