import React, { ChangeEvent, useEffect, useState } from "react";
import MyProfileForm from "./MyProfileForm";
import { initialProfileState, profileOptions } from "../State";
import { APICALL } from "../../../../services/ApiServices";
import { createUser, manageUser } from "../../../../routes/ApiEndpoints";
import CustomNotify from "../../../atoms/CustomNotify";
import { t } from "../../../../services/translation/TranslationUtils";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../../features/auth/AuthSlice";
import { useNavigate } from "react-router-dom";
import { Files } from "../../../common/CommonInterfaces";
import S3Services from "../../../../utils/S3Bucket/S3Services";

const MyProfileOrganism: React.FC = () => {
    const [state, setState] = useState(initialProfileState);
    const user = useSelector(selectAuth);
    const navigate = useNavigate();
    
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            let postdata = {
                options: profileOptions
            };
            const response = await APICALL.service(manageUser + '/' + user.userId, 'POST', postdata);

            if (response?.status === 200) {
                updateState(response);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateState = (response: any) => {
        let data = response?.data;
        setState((prevState: any) => ({
            ...prevState,
            form: {
                ...prevState.form,
                first_name: data?.profile?.first_name ?? '',
                last_name: data?.profile?.last_name ?? '',
                phone: data?.phone,
                role: data?.roles?.[0]?.title,
                title: data?.profile?.title,
                language: data?.languages?.[0]?.id,
                user_id: user.userId,
                profile_picture: {
                    file: null,
                    file_path: data?.profile?.profile_picture?.file_path,
                    file_name: data?.profile?.profile_picture?.file_name
                }
            },
            options: response?.options
        }))
    }

    const removeError = (fieldName: any) => {
        if (initialProfileState.errors.hasOwnProperty(fieldName)) {
            setState((prevState: any) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    [fieldName]: ""
                }
            }));
        }
    };

    const handleProfileUpload = (file: File, field: any) => {
        const newFile: Files = {
            file,
            file_path: file && URL.createObjectURL(file),
            file_name: file?.name,
        };

        setState((prevState: any) => ({
            ...prevState,
            form: {
                ...prevState.form,
                [field.name]: newFile
            }
        }));
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
        if (e != null) {
            const { name, value } = e.target ?? e;
            setState((prevState: any) => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    [field ?? name]: value,
                }
            }));
        } else {
            field &&
                setState((prevFormData: any) => ({ ...prevFormData, [field]: null }));
        }
    }

    const handleSubmit = async (errors: any, valid: boolean) => {
        if (valid) {
            try {
                let filePath = '';
                const uploadPromises: Promise<void>[] = [];
                let profile_picture = state?.form?.profile_picture;
                const loadingIcon = document.getElementById("loading-div-id");
                if (loadingIcon) loadingIcon.style.display = "block";
                console.log(profile_picture.file);
                if ('file' in profile_picture && profile_picture.file) {
                    const uploadPromise = S3Services.uploadToS3([profile_picture.file], 'profile/')
                        .then((uploadedFiles) => {
                            filePath = uploadedFiles[0];
                        });
                    uploadPromises.push(uploadPromise);
                }
                await Promise.all(uploadPromises);
                if (loadingIcon) loadingIcon.style.display = "none";
                let postData = {
                    ...state.form,
                    profile_picture: filePath
                }

                const response = await APICALL.service(createUser, 'POST', postData);

                if (response?.status === 200) {
                    CustomNotify({
                        type: 'success',
                        message: t('Profile updated successfully')
                    });
                    navigate('/');
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            setState((prevState: any) => ({
                ...prevState,
                errors: errors
            }));
        }
    }

    return (
        <MyProfileForm
            removeError={removeError}
            handleChange={handleChange}
            options={state.options}
            data={state?.form}
            errors={state.errors}
            handleSubmit={handleSubmit}
            handleFileUpload={handleProfileUpload}
        />
    );
};

export default MyProfileOrganism;
