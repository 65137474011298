import { useEffect, useState } from "react";
import { t } from "../../services/translation/TranslationUtils";
import ListSelectField from "./ListSelectField";
import Pagination from "./Paginations";

interface Option {
    value: string;
    name: string;
    label: string;
    profile_pic?: string;
}

interface PaginatedListSelectFieldProps {
    field: any;
    formData: any;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => void;
    search?: any;
}

const PaginatedListSelectField: React.FC<PaginatedListSelectFieldProps> = ({
    field,
    formData,
    handleInputChange,
    search
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 2;
    const options = field?.options ?? [];
    const totalPages = Math.ceil(options.length / itemsPerPage);

    useEffect(() => {
        setCurrentPage(1);
    }, [search]);

    const handlePaginationClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const paginatedOptions = options.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <div className='border-top '>
            {options.length <= 0 ? (
                t("No result found")
            ) : (
                <div className={field?.class?.feildclass ?? ''}>
                {options.map((option: Option) => (
                    <ListSelectField
                        key={option.value}
                        name={option.name}
                        path={option?.profile_pic}
                        checked={formData[field?.name]?.includes(option.value)}
                        onChange={(e) => handleInputChange(e, field?.name)}
                        iconLabel={option.label}
                        id={option.value}
                    />
                ))}
                </div>
            )}
            {/* {totalPages > 1 && <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePaginationClick={handlePaginationClick}
            />} */}
        </div>
    );
};

export default PaginatedListSelectField;
