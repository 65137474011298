import React, { useState } from "react";
import { SurveyProps } from "../../../../pages/news/context/Interfaces";
import CheckBoxField from "../../../atoms/CheckBoxField";
import Button from "../../../atoms/Button";
import LabelField from "../../../atoms/LabelField";
import '../CreateNewsOrganism.css';
import { APICALL } from "../../../../services/ApiServices";
import { ACTION_POSTS } from "../../../../routes/ApiEndpoints";
import { t } from "../../../../services/translation/TranslationUtils";

const ShowSurvey: React.FC<SurveyProps> = ({
    survey,
    postId,
    fetchPost
}) => {
    const [state, setState] = useState({
        selectedOptions: survey?.my_results,
        showResults: false
    })

    const handleOptionChange = (optionId: number) => {
        setState((prevState: any) => {
            const selectedOptions = prevState.selectedOptions ?? [];
            const isSelected = selectedOptions.includes(optionId);

            let newSelectedOptions = selectedOptions;

            if (isSelected) {
                newSelectedOptions = selectedOptions.filter((id: number) => id !== optionId);
            } else if (survey.ismulti) {
                if (selectedOptions.length < survey.max_number_of_answers) {
                    newSelectedOptions = [...selectedOptions, optionId];
                }
            } else {
                newSelectedOptions = [optionId];
            }

            return { ...prevState, selectedOptions: newSelectedOptions };
        });
    };

    const getCounts = (optionId: number, percentage: boolean = false) => {
        const optionVotes = survey?.results.filter((result: any) => result.option_id === optionId).length;
        if (percentage) {
            return (optionVotes / survey?.results_count) * 100;
        } else {
            return optionVotes;
        }
    }

    const showOrHideResults = () => {
        setState((prevState: any) => ({
            ...prevState,
            showResults: !prevState.showResults
        }));
    }

    const handleSubmit = async () => {
        let postData = {
            type: 'survey',
            survey_id: survey.id,
            options: state.selectedOptions
        };

        const response = await APICALL.service(ACTION_POSTS, 'POST', postData);

        if (response.status === 200) {
            fetchPost(postId);
        }
    }

    return (
        <div className="mt-3">
            <div className="d-flex">
                <div className="flex-1">
                    {survey?.options?.map((option: any) => (
                        <div key={option.id}>
                            <CheckBoxField
                                label={option.title}
                                checked={state.selectedOptions.includes(option.id)}
                                onCheck={() => handleOptionChange(option.id)}
                            />
                        </div>
                    ))}
                </div>
                <Button
                    className="search_btn ms-2 height_maxcontent"
                    title={`${state.showResults ? 'Hide' : 'Show'} results`}
                    handleClick={showOrHideResults}
                />
            </div>
            <Button
                className={'search_btn mt-3'}
                title={t('Answer')}
                handleClick={handleSubmit}
            />
            {state.showResults && <div className="mt-3">
                {survey?.options?.map((option: any) => (
                    <React.Fragment key={option.id}>
                        <div className="d-flex">
                            <div className="flex-1">
                                <LabelField
                                    title={option.title}
                                />
                            </div>
                            <div>
                                <LabelField
                                    title={`${getCounts(option.id)} Votes`}
                                />
                            </div>
                        </div>
                        <div className="progress-bar">
                            <div
                                className={`progress-bar-fill`}
                                style={{ 
                                    width: `${getCounts(option.id, true)}%`, 
                                    height: getCounts(option.id, true) ? '100%' : undefined 
                                }}
                            />
                        </div>
                    </React.Fragment>
                ))}
            </div>}
        </div>
    );
};

export default ShowSurvey;