import './chats.css';
import React, { useState, useEffect, useRef } from 'react';
import './chats.css';
import io from "socket.io-client";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../features/auth/AuthSlice';
import { t } from '../../../services/translation/TranslationUtils';
import Send from '../../../utils/icons/Send';
import Seen from '../../../utils/icons/Seen';
import Unseen from '../../../utils/icons/Unseen';
import { CHATS_URL } from '../../../routes/ApiEndpoints';

const socket = io.connect(CHATS_URL);

function ChatsOrganism() {
    const auth = useSelector(selectAuth);
    const [message, setMessage] = useState("");
    const [user, setUser] = useState("");
    const [messages, setMessages] = useState({});
    const [connectedUsers, setConnectedUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [unreadMessages, setUnreadMessages] = useState({});
    const [lastMessages, setLastMessages] = useState({});

    useEffect(() => {
        // Listen for receive_direct_message event
        socket.on("receive_direct_message", (data) => {
            console.log("receive_direct_message")
            setMessages((prevMessages) => ({
                ...prevMessages,
                [data.from]: [...(prevMessages[data.from] || []), { ...data }]
            }));
            if (data.from !== selectedUser) {
                setUnreadMessages(prev => ({
                    ...prev,
                    [data.from]: (prev[data.from] || 0) + 1
                }));
            }

            setLastMessages(prevLastMessages => ({
                ...prevLastMessages,
                [data.from]: { message: data.message, time: data.time }
            }));
            inputRef.current.focus();

            // Reorder connected users
            setConnectedUsers(prevUsers => {
                const updatedUsers = prevUsers.filter(users => users.username !== data.from);
                updatedUsers.unshift({ username: data.from });
                return updatedUsers;
            });
        });

        // Listen for messages_seen event
        socket.on("messages_seen", (data) => {
            console.log("messages_seen")
            const { from } = data;
            setMessages((prevMessages) => {
                const userMessages = prevMessages[from] || [];
                const updatedMessages = userMessages.map(msg => ({
                    ...msg,
                    seen: true,
                }));
                return {
                    ...prevMessages,
                    [from]: updatedMessages,
                };
            });
            inputRef.current.focus();
        });

        // Listen for message_sent event
        socket.on("message_sent", (data) => {
            console.log("message_sent")
            setMessages((prevMessages) => ({
                ...prevMessages,
                [data.to]: [...(prevMessages[data.to] || []), { ...data }]
            }));

            setLastMessages(prevLastMessages => ({
                ...prevLastMessages,
                [data.to]: { message: data.message, time: data.time }
            }));

            // Reorder connected users
            setConnectedUsers(prevUsers => {
                const updatedUsers = prevUsers.filter(users => users.username !== data.to);
                updatedUsers.unshift({ username: data.to });
                return updatedUsers;
            });

            inputRef.current.focus();
        });

        // Listen for messages_fetched event
        // socket.on("messages_fetched", (data) => {
        //     console.log("messages_fetched")
        //     const { from, to, messages } = data;
        //     setMessages((prevMessages) => ({
        //         ...prevMessages,
        //         [from]: messages
        //     }));

        //     if (messages.length > 0) {
        //         const lastMessage = messages[messages.length - 1];
        //         setLastMessages(prevLastMessages => ({
        //             ...prevLastMessages,
        //             [from]: { message: lastMessage.message, time: lastMessage.time }
        //         }));
        //     }
        //     inputRef.current.focus();
        // });

        // Clean up socket event listeners
        return () => {
            socket.off("update_users");
            socket.off("receive_direct_message");
            socket.off("messages_seen");
            socket.off("message_sent");
            socket.off("messages_fetched");
        };

    }, [selectedUser, messages]);


    useEffect(() => {
        socket.on("update_users", (users) => {
            console.log("update_users")
            setConnectedUsers(users);
        });
        return () => {
            socket.off("update_users");
        };
    }, [user])

    useEffect(() => {
        // Handle visibility change to mark messages as seen
        if (selectedUser) {
            const handleVisibilityChange = () => {
                if (document.visibilityState === 'visible') {
                    socket.emit("mark_seen", { from: selectedUser, to: user });
                }
            };
            document.addEventListener("visibilitychange", handleVisibilityChange);
            window.addEventListener("focus", handleVisibilityChange);

            return () => {
                document.removeEventListener("visibilitychange", handleVisibilityChange);
                window.removeEventListener("focus", handleVisibilityChange);
            };
        }
    }, [selectedUser, user]);

    // useEffect(() => {
    //     console.log("join users")
    //     if (auth?.fullname) {
    //         setUser(auth?.fullname);
    //         socket.emit("joinuser", auth?.fullname);
    //     }
    // }, [auth?.fullname]);


    useEffect(() => {
        if (auth?.fullname) {
            setUser(auth?.fullname);
            socket.emit("joinuser", auth?.fullname);
            // Fetch last messages immediately after joining
            socket.on("messages_fetched", (data) => {
                const { from, to, messages } = data;
                setMessages((prevMessages) => ({
                    ...prevMessages,
                    [from]: messages
                }));
                if (messages.length > 0) {
                    const lastMessage = messages[messages.length - 1];
                    setLastMessages(prevLastMessages => ({
                        ...prevLastMessages,
                        [from]: { message: lastMessage.message, time: lastMessage.time }
                    }));
                }

            });
            connectedUsers.forEach(userObj => {
                if (userObj.username !== auth?.fullname) {
                    socket.emit("fetch_messages", { from: userObj.username, to: auth?.fullname });
                }
            });
        }
        return () => {
            socket.off("update_users");
            socket.off("receive_direct_message");
            socket.off("messages_seen");
            socket.off("message_sent");
            socket.off("messages_fetched");
        };
    }, [auth?.fullname, connectedUsers]);



    const sendMessage = () => {
        // Event when user sends a message
        if (message.trim() !== "") {
            if (selectedUser) {
                socket.emit("send_direct_message", { to: selectedUser, message });
            }
            setMessage("");
        }
        inputRef.current.focus();
    }

    const inputRef = useRef(null);

    const handleUserClick = (username) => {
        // Event when user clicks on a user to chat
        setSelectedUser(username);
        setUnreadMessages(prev => ({
            ...prev,
            [username]: 0
        }));
        if (messages[username]) {
            socket.emit("mark_seen", { from: username, to: user });
        }
        socket.emit("fetch_messages", { from: username, to: user });
        inputRef.current.focus();
    }


    // Update window height on resize
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 150);
    useEffect(() => {
        const updateWindowHeight = () => {
            setWindowHeight(window.innerHeight - 150);
        };
        window.addEventListener('resize', updateWindowHeight);
        return () => window.removeEventListener('resize', updateWindowHeight);
    }, []);

    const getFormattedDate = (time) => {
        const currentdate = new Date();
        const currenttimezoneOffset = currentdate.getTimezoneOffset() * 60 * 1000; // To set time zone offset
        const currentdateformattedDate = new Date(currentdate.getTime() - currenttimezoneOffset).toISOString().slice(0, 10);
        const date = new Date(time);
        const timezoneOffset = date.getTimezoneOffset() * 60 * 1000; // To set time zone offset
        const formattedDate = new Date(date.getTime() - timezoneOffset).toISOString().slice(0, 10);
        if (currentdateformattedDate == formattedDate) {
            return "Today";
        } else if (date.getDate() == currentdate.getDate() - 1) {
            return "yesterday"
        } else {
            return formattedDate;
        }
    };

    const groupMessagesByDate = (messages) => {
        return messages.reduce((acc, msg) => {
            const formattedDate = getFormattedDate(msg.timestamp);
            if (!acc[formattedDate]) {
                acc[formattedDate] = [];
            }
            acc[formattedDate].push(msg);
            return acc;
        }, {});
    };

    return (
        <div className="main chats" style={{ height: `${windowHeight}px` }}>
            <div className='user_block h-100 overflow-auto '>
                <div className='bg-white position-sticky top-0'>
                    <div className='username_block p-3'>
                        <div className='d-flex justify-content-between'>
                            <div className='username'>
                                {user && user}
                            </div>
                        </div>

                    </div>
                    <div className='px-3 p-2 border-top border-bottom border-3'>{t("Chats")}</div>
                </div>

                <div className='mt-2 bg-white'>
                    {connectedUsers.filter((userObj) => userObj.username !== user).map((userObj) => (
                        <div className='users_names p-2 px-3 d-flex justify-content-between border-bottom' key={userObj.username} onClick={() => handleUserClick(userObj.username)} style={{ cursor: 'pointer', background: selectedUser === userObj.username ? '#00a5ce4d' : '' }}>
                            <div className='w-100'>
                                <div className='d-flex justify-content-between'>
                                    <span className='text-truncate'>{userObj.username}</span>
                                    <small>{lastMessages[userObj.username]?.time}</small>
                                </div>
                                <div className='last_message d-flex justify-content-between'>
                                    <small className='text-truncate'>{lastMessages[userObj.username]?.message}</small>
                                    {unreadMessages[userObj.username] > 0 && <span className='msg_count'>{unreadMessages[userObj.username]}</span>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>


            <div className='message_block h-100 overflow-auto'>
                <div className='container h-100'>
                    <div className='d-flex flex-column h-100'>
                        <div className='connected_user border-bottom py-3 px-4'>
                            {t("Connected to")}: {selectedUser}
                        </div>
                        <div className="message-history flex-grow-1 overflow-auto pe-3">
                            {Object.entries(groupMessagesByDate(messages[selectedUser] || [])).reverse().map(([date, msgs]) => (
                                <div key={date}>
                                    <div className='msgdate'><span className='mx-3'>{date}</span></div>
                                    {msgs.map((msg, index) => (
                                        <div key={index} className={`${msg.from === user ? 'text-end' : 'text-start'} my-3`}>
                                            <div className='msg_time mb-1'>{msg.from === user ? "" : msg.from} {msg.time}</div>
                                            <span className={`message ${msg.from === user ? 'own' : 'other'} p-2 rounded text-break`}>
                                                {msg.message} {msg.from === user && (msg.seen ? <Seen /> : <Unseen />)}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>

                        <div className='d-flex my-3' >
                            <input ref={inputRef} className='form-control me-2' placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    sendMessage();
                                }
                            }} />
                            <div onClick={sendMessage} className='msgSend_btn'><Send /></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatsOrganism;