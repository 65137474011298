import CreateDocumentsOrganism from '../../components/organisms/documents/CreateDocumentsOrganism';
import { DocumentProvider } from './context/GroupContext';

const CreateDocuments = () => {
    return (
        <DocumentProvider>
            <CreateDocumentsOrganism />
        </DocumentProvider>
    );
};

export default CreateDocuments;
