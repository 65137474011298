// src/pages/groups/context/State.tsx
import { FormMode } from "../../../components/common/CommonEnums";
import CommonServices from "../../../services/CommonService";
import { GroupState } from "./Interfaces";
import { filterOptions } from "./Utils";

export const initialState: GroupState = {
    mode: FormMode.CREATE,
    tabs: [
        {
            id: "description",
            title: "Description",
            showStatus: true,
            error: false,
            draft: false,
        },
        {
            id: "user",
            title: "Users",
            showStatus: true,
            error: false,
            draft: false,
        },
        {
            id: "team",
            title: "Teams/Departments",
            showStatus: true,
            error: false,
            draft: false,
        },
        {
            id: "admin",
            title: "Admins",
            showStatus: true,
            error: false,
            draft: false,
        },
    ],
    description: {
        data: {
            title: "",
            description: "",
            group_type_id: "",
        },
        errors: {
            title: "",
            description: "",
            group_type_id: "",
        },
    },
    user: {
        data: {
            user: [],
        },
        errors: {
            user: "",
        },
    },
    team: {
        data: {
            team: [],
        },
        errors: {
            team: "",
        },
    },
    admin: {
        data: {
            admin: [],
        },
        errors: {
            admin: "",
        },
    },
    currentTab: "description",
    options: {
        Group: [],
        GroupType: [],
        User: [],
    },
    search: "",
    initialCall: true
};

export const getFormFields = (state: GroupState) => {
    const formFields: any = {
        description: [
            {
                0: {
                    name: 'title',
                    title: 'Title',
                    type: 'text',
                    required: true,
                    class: {
                        // mainclass: '',
                        // labelclass: '',
                        // feildclass: '',
                    },
                },
            },
            {
                0: {
                    name: 'description',
                    title: 'Description',
                    type: 'textarea',
                    required: false,
                    class: {
                        // mainclass: '',
                        // feildclass: '',
                    },
                },
            },
            {
                0: {
                    name: 'group_type_id',
                    title: 'Type',
                    type: 'radio',
                    required: true,
                    options: (state['options']?.GroupType)?.map((item: any) => { return { ...item, label: CommonServices?.capitalizeLabel(item?.label)}}),
                    class: {
                        // mainclass: '',
                        // labelclass: '',
                        // buttonclass: '',
                    },
                },
            }
        ],
        user: [
            {
                0: {
                    name: 'user',
                    title: 'Users',
                    type: 'listwithselect',
                    required: false,
                    options: filterOptions(state['options']?.User, (state?.search)?.trim()),
                    class: {
                        // mainclass: '',
                        // labelclass: '',
                        // buttonclass: '',
                    },
                },
            }
        ],
        team: [
            {
                0: {
                    name: 'team',
                    title: 'Teams/Departments',
                    type: 'listwithselect',
                    required: false,
                    options: filterOptions(state['options']?.Group, (state?.search)?.trim()),
                    class: {
                        // mainclass: '',
                        // labelclass: '',
                        // buttonclass: '',
                    },
                },
            }
        ],
        admin: [
            {
                0: {
                    name: 'admin',
                    title: 'Admins',
                    type: 'listwithselect',
                    required: false,
                    options: filterOptions(state['options']?.User, (state?.search)?.trim()),
                    class: {
                        // mainclass: '',
                        // labelclass: '',
                        // buttonclass: '',
                    },
                },
            }
        ],
    };

    return formFields[state.currentTab] || [];
};
