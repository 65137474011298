import React, { useEffect, useRef, useState } from "react";
import { viewState } from "../../../../pages/news/context/State";
import { APICALL } from "../../../../services/ApiServices";
import { GET_POST, MANAGE_NEWS } from "../../../../routes/ApiEndpoints";
import ImageField from "../../../atoms/ImageField";
import { t } from "../../../../services/translation/TranslationUtils";
import LabelField from "../../../atoms/LabelField";
import '../CreateNewsOrganism.css';
import Button from "../../../atoms/Button";
import ViewPosts from "./ViewPosts";
import CreatePostOrganism from "../../posts/CreatePostOrganism";

const ViewNewsOrganism: React.FC = () => {
    const [state, setState] = useState(viewState);
    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get("id");
    const createPostRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        fetchData();
    }, [view]);

    const fetchData = async (pageNumber = 1) => {
        setState((prevState: any) => ({
            ...prevState,
            isFetching: true
        }));
        let postData = {
            ...state.postData,
            current_page: pageNumber,
            posts: true
        }
        const response = await APICALL.service(MANAGE_NEWS + '/' + view, 'POST', postData);

        if (response.status === 200) {
            const updatedPostData = {
                ...state.postData,
                current_page: pageNumber,
                last: response?.data?.posts?.length < state?.postData?.limit,
                options: false,
            };

            const newData = pageNumber === 1 ?
                response.data :
                {
                    ...state.data,
                    posts: [
                        ...state.data.posts,
                        ...response.data.posts
                    ]
                };

            setState((prevState: any) => ({
                ...prevState,
                data: newData,
                options: response?.data?.options ? response?.data?.options : state.options,
                postData: updatedPostData,
                isFetching: false
            }));
        }
    }

    useEffect(() => {
        if (state.createpost && createPostRef.current) {
            createPostRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [state.createpost]);

    const handleSave = () => {
        setState((prev: any) => ({ ...prev, createpost: !state?.createpost }));
        fetchData();
    }

    const handleScroll = () => {
        const { innerHeight } = window;
        const { scrollTop, offsetHeight } = document.documentElement;
        if (innerHeight + scrollTop + 10 >= offsetHeight &&
            !state.postData.last &&
            !state.isFetching
        ) {
            fetchData(state.postData.current_page + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const updatePost = async (id: number) => {
        const response = await APICALL.service(`${GET_POST}/${id}`, 'GET');

        const updatedPosts = state.data.posts.map((post: any) => {
            if (post.id === id) {
                return response.data;
            }
            return post;
        });

        setState((prevState: any) => ({
            ...prevState,
            data: {
                ...prevState.data,
                posts: updatedPosts
            }
        }));
    }

    return (
        <>
            <div className="bg-light d-flex align-items-center py-3">
                <div className="flex-1 ms-3">
                    <ImageField
                        className={`thumbnail-image`}
                        value={state?.data?.news_info?.header?.file_path ?? "../static/images/profile.png"}
                        altText={t('header')}
                    />
                    <LabelField
                        className="ms-2"
                        title={state?.data?.title}
                    />
                </div>
                <div className="me-3">
                    <Button
                        className="search_btn"
                        handleClick={() => setState((prev: any) => ({ ...prev, createpost: !state?.createpost }))}
                        title={t('Create post')}
                    />
                </div>
            </div>
            <ViewPosts
                data={state?.data}
                fetchPost={updatePost}
            />
            {state?.createpost &&
                <div ref={createPostRef}>
                    <CreatePostOrganism
                        newsid={Number(view)}
                        handleSave={() => handleSave()}
                        options={state?.options}
                    />
                </div>
            }
        </>
    );
};

export default ViewNewsOrganism;
