
export const initialstate = {
    form: {
        // title: "",
        text: '',
        header: {
            file: "",
            file_name: "",
            file_path: ""
        },
        thumbnails: {
            file: "",
            file_name: "",
            file_path: ""
        },
        users: [],
        groups: [],
        expired_at: null
    },
    errors: {
        text: '',
        header: '',
        thumbnails: '',
        users: '',
        groups: '',
    },
    survey_popup: false,
    options: {
        User: [],
        Groups: []
    }
}

export const OptionsPayload = {
    data: [
        {
            model: "User",
            filters: { status: [true] },
        },
        {
            model: "Group",
            filters: { status: [true] },
        }
    ],
};

export const filterValues = (options: any) => {    
    let row = [
        {
            0: {
                type: 'textarea',
                name: 'text',
                title: 'Description',
                required: true,
            }
        },
        {
            0: {
                name: 'users',
                title: 'Users',
                type: 'multi-select',
                required: true,
                value: [],
                options: options?.User ?? [],
                class: {
                     mainclass: 'mb-3',
                    // labelclass: '',
                    // buttonclass: '',
                },
            },
            1: {
                name: 'groups',
                title: 'Teams/departments',
                type: 'multi-select',
                required: false,
                value: [],
                options: options?.Group ?? [],
                class: {
                    // mainclass: '',
                    // labelclass: '',
                    // buttonclass: '',
                },
            },
        },
        {
            0: {
                title: 'Expiry',
                name: 'expired_at',
                type: 'date',
                value: null,
                required: true,
                mindate: new Date(),
                placeholder: "Expiry"
            },
        }
        // {
        //     0: {
        //         type: 'file',
        //         name: 'header',
        //         required: false,
        //         title: 'Upload header',
        //         accept: '.png, .jpg, .jpeg, gif',
        //         message: "Please upload an image file",
        //         preview: true,
        //     },
        //     1: {
        //         type: 'file',
        //         name: 'thumbnails',
        //         required: false,
        //         title: 'Upload thumbnails',
        //         accept: '.png, .jpg, .jpeg, gif',
        //         message: "Please upload a image file",
        //         preview: true,
        //     }
        // }
    ];
    return row;
}

/******* Manage page */
export const tableHeaders = [
    { name: 'Message', width: 40 },
    { name: 'Users', width: 10 },
    { name: 'Team/Departments', width: 10 },
    { name: 'Created by', width: 12 },
    { name: 'Expiry', width: 10 },
    { name: 'Created at', width: 10 },
];

export const tableValues = [
    'text',
    'users',
    'groups',
    'created_by',
    'expired_at',
    'created_at'
];

export const manageState = {
    filters: {
        search: "",
        user: [],
        from: null,
        to: null
    },
    popup: {
        archivepopup: {
            id: null,
            status: null,
            showpopup: false
        }
    },
    options: {} as any,
    data: [] as any,
    manage: true,
    initialCall: true,
    current_page: 1,
    limit: 10,
    totalPages: 0,
    offset: 0,
    totalCount: 0,
    refresh: true
};

export const filterManageValues = (options: any) => {    
    let row = [
        {
            name: 'user',
            type: 'multi-select-without-label',
            placeholder: 'Select user',
            value: [],
            options: options?.User,
        },
        {
            name: 'search',
            class: {
                mainclass: 'h-100',
                feildclass: 'h-100'
            },
            type: 'text',
            placeholder: 'Search message',
            value: "",
            label: true
        },
        {
            name: 'from',
            type: 'date',
            value: null,
            label: true,
            placeholder: 'Select from date',
        },
        {
            name: 'to',
            type: 'date',
            value: null,
            label: true,
            placeholder: 'Select to date',
        },
    ]
    return row;
}
/******* Manage page */
