import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAuth } from "../features/auth/AuthSlice";

interface PublicRouteProps {
  element: ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const user = useSelector(selectAuth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.token) {
      navigate('/');
    }
  }, [user, navigate]);

  return user.token ? null : element;
};

export default PublicRoute;
