import React from 'react'

export const VideoUpload = () => {
  return (

    <svg version="1.0"
      width="35px" height="35px" viewBox="0 0 24.000000 24.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M23 199 c21 -12 22 -35 1 -47 -10 -6 -14 -20 -12 -43 3 -33 4 -34 54
-37 50 -3 51 -2 58 28 4 23 14 35 36 43 23 9 30 17 30 39 l0 28 -92 0 c-68 -1
-88 -3 -75 -11z m47 -3 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10
11 6 0 10 -2 10 -4z m55 -6 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10
16 10 6 0 7 -4 4 -10z m55 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10
11 10 2 0 4 -4 4 -10z"/>
        <path d="M0 175 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15
-7 -15 -15z"/>
        <path d="M147 122 c-33 -36 -8 -92 40 -92 25 0 53 30 53 57 0 46 -62 69 -93
35z m54 -19 c10 -11 10 -14 2 -9 -9 5 -13 -1 -14 -21 l-2 -28 -4 28 c-3 18 -9
25 -16 20 -7 -4 -6 0 2 10 7 9 15 17 16 17 1 0 9 -8 16 -17z"/>
      </g>
    </svg>

  )
}
