import React from 'react';
import { TabComponentProps } from '../../../../../pages/news/context/Interfaces';
import InputTextfield from '../../../../atoms/InputTextField';
import { t } from '../../../../../services/translation/TranslationUtils';
import { renderInput } from '../../../../../services/form/FormElements';

const CommonTab: React.FC<TabComponentProps> = ({ 
    state, 
    dispatch, 
    handleChange, 
    formConfig,
    removeError,
    handleSearch
}) => {
    return (
        <div>
            {handleSearch && 
                <InputTextfield
                    name='search'
                    handleChange={(e) => handleSearch(e)}
                    placeholder={t('Search')}
                    value={state.search}
                    className='form-control shadow-none ms-auto w-25'
                />
            }
            <div className="mt-3">
                {formConfig.map((fields: any, index: number) => (
                    <div key={index} className="">
                        {Object.values(fields)?.map((field: any) => (                           
                            <div key={field.name} className="">
                                {renderInput(
                                    removeError,
                                    field,
                                    (e: any, fieldName: string) => handleChange(e, fieldName),
                                    state[state?.currentTab]?.data,
                                    state[state?.currentTab]?.errors,
                                    state?.search
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CommonTab;
