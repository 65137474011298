import React from "react";
import { Modal, Button } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { t } from "../../services/translation/TranslationUtils";

interface PopupProps {
	title?: string;
	body: React.ReactNode;
	cancel?: () => void;
	submit?: () => void;
	close?: () => void;
	yesicon?: React.ReactNode;
	noicon?: React.ReactNode;
	yestext?: string;
	notext?: string;
	modalSize?: "sm" | "lg" | "xl";
	bodyclassName?: string;
	modalclass?: string;
}

const Popup: React.FC<PopupProps> = ({
	title,
	body,
	cancel,
	submit,
	close,
	yestext,
	notext,
	yesicon,
	noicon,
	modalSize,
	bodyclassName,
	modalclass,
}) => {
	return (
		<Modal
			size={modalSize ?? "lg"}
			show={true}
			centered
			className={modalclass}
			// onHide={close ?? cancel}
		>
			<Modal.Header>
				{title && <Modal.Title>{title}</Modal.Title>}
				<Button
					variant="link"
					className="btn-close shadow-none mt-1"
					onClick={close ?? cancel}
					aria-label="Close"
					title={t("Close")}
				>
					{/* <AiFillCloseCircle /> */}
				</Button>
			</Modal.Header>
			<Modal.Body className={bodyclassName ?? 'text-center text-break mb-3 px-0'}>
				{body}
			</Modal.Body>
			{(notext || yestext) && (
				<Modal.Footer className={`border-0 px-3 justify-content-${yestext && notext ? "between" : "center"} mb-3`}>
					{notext && (
						<Button
							variant="secondary"
							className="back_btn"
							onClick={cancel}
						>
							{noicon && noicon} {notext}
						</Button>
					)}
					{yestext && (
						<Button
							className="create_button"
							onClick={submit}
						>
							{yesicon && yesicon}
							{yestext}
						</Button>
					)}
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default Popup;
