import React from 'react';
import { useNavigate } from "react-router-dom";
import { t } from "../../../../services/translation/TranslationUtils";
import Button from "../../../atoms/Button";
import InputTextfield from "../../../atoms/InputTextField";
import LabelField from "../../../atoms/LabelField";
import { PasswordProps } from "../Types";
import Closeeye from "../../../../utils/icons/Closeeye";
import Openeye from "../../../../utils/icons/Openeye";
import FormValidation from '../../../../services/formvalidation/Validation';
import ErrorMessage from '../../../atoms/ErrorMessage';

const ChangePasswordForm: React.FC<PasswordProps> = ({
    state,
    changeHandler,
    togglePassword,
    submitHandler,
    removeError
}) => {
    const navigate = useNavigate();

    const validate = () => {
        const error: any = {
            current_password: '',
            new_password: ''
        };

        error.current_password = FormValidation.emptyValidation(state.form.current_password);
        error.new_password = FormValidation.passwordValidation(state.form.new_password, state.form.confirm_password);
        const valid = Object.values(error)?.every(message => message === '');
        submitHandler(error, valid);
    }

    return (
        <div className="p-3">
            <h2>{t("Reset password")}</h2>
            <div className="form p-5">
                <div className="my-4">
                    <LabelField
                        title={"Current password"}
                        mandatory={true}
                    />
                    <div className="d-flex position-relative">
                        <InputTextfield
                            handleChange={changeHandler}
                            handleClick={() => removeError('current_password')}
                            type={state.type.current_password}
                            value={state.form.current_password}
                            placeholder={t("Enter the old password")}
                            name="current_password"
                            className="form-control shadow-none rounded-0"
                        />
                        <p
                            onClick={() => togglePassword('current_password')}
                            className="position-absolute mt-1 me-2 end-0 cursor-pointer"
                        >
                            {state.type.current_password === "password" ? (
                                <Closeeye />
                            ) : (
                                <Openeye />
                            )}
                        </p>
                    </div>
                    {state.errors.current_password && <ErrorMessage errorMessage={state.errors.current_password} />}
                </div>
                <div className="my-4">
                    <LabelField
                        title={"New password"}
                        mandatory={true}
                    />
                    <div className="d-flex position-relative">
                        <InputTextfield
                            handleChange={changeHandler}
                            handleClick={() => removeError('new_password')}
                            type={state.type.new_password}
                            value={state.form.new_password}
                            placeholder={t("Enter the new password")}
                            name="new_password"
                            className="form-control shadow-none rounded-0"
                        />
                        <p
                            onClick={() => togglePassword('new_password')}
                            className="position-absolute mt-1 me-2 end-0 cursor-pointer"
                        >
                            {state.type.new_password === "password" ? (
                                <Closeeye />
                            ) : (
                                <Openeye />
                            )}
                        </p>
                    </div>
                    {state.errors.new_password && <ErrorMessage errorMessage={state.errors.new_password} />}
                </div>
                <div className="my-4">
                    <LabelField
                        title={"Confirm password"}
                        mandatory={true}
                    />
                    <div className="d-flex position-relative">
                        <InputTextfield
                            handleChange={changeHandler}
                            type={state.type.confirm_password}
                            value={state.form.confirm_password}
                            placeholder={t("Confirm your password")}
                            name="confirm_password"
                            className="form-control shadow-none rounded-0"
                        />
                        <p
                            onClick={() => togglePassword('confirm_password')}
                            className="position-absolute mt-1 me-2 end-0 cursor-pointer"
                        >
                            {state.type.confirm_password === "password" ? (
                                <Closeeye />
                            ) : (
                                <Openeye />
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between mt-4">
                <Button
                    className="back_btn"
                    handleClick={() => navigate('/employee/profile')}
                    title={t('Back')}
                />
                <Button
                    className="create_button"
                    handleClick={validate}
                    title={t('Save')}
                />
            </div>
        </div>
    );
};

export default ChangePasswordForm;
