import { S3_BUCKET_NAME, S3_FOLDER, s3 } from "../../config/aws-config";

const S3Services = {
    uploadToS3: async (fileList: any, pathstring: string) => {
        const totalFiles = fileList.length;
        let filesData: any = [];
        if (fileList && totalFiles > 0) {
            const uploadPromises = Array.from(fileList).map((file: any) => {
                const timestamp = Math.floor(new Date().getTime() / 1000);
                const randomSuffix = Math.floor(Math.random() * 1000) + 1;
                const fileExtension = file.name.split('.').pop();
                const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
                const newFileName = `${timestamp}${randomSuffix}${fileName}.${fileExtension}`;
                const params = {
                    Bucket: S3_BUCKET_NAME,
                    Key: `${S3_FOLDER}${pathstring}${newFileName}`,
                    Body: file,
                    ContentType: file.type,
                    ACL: 'public-read',
                };
                return new Promise((resolve, reject) => {
                    s3.upload(params, (err: any, data: any) => {
                        if (err) {
                            console.error('Error uploading image:', err);
                            reject(err);
                        } else {
                            filesData.push(data.Location);
                            resolve(data.Location);
                        }
                    });
                });
            });
            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error('One or more uploads failed:', error);
            }
        }
        return filesData;
    }
};

export default S3Services;
