import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface AuthSate {
  userId: number | null;
  token: string;
  email: string;
  username: string;
  phone: string;
  authenticated: boolean;
  language: number | null;
  languageOptions: any;
  fullname: string;
}

const initialState: AuthSate = {
  userId: null,
  token: "",
  email: "",
  fullname: "",
  username: "",
  phone: "",
  authenticated: false,
  language: null,
  languageOptions: [],
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addUserDetails: (state, action: PayloadAction<object>) => {
      return { ...state, ...action.payload };
    },
    removeUserDetails: () => {
      return initialState;
    }
  },
});

export const { addUserDetails, removeUserDetails } = AuthSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default AuthSlice.reducer;
