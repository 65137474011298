import React from 'react'

const Closeeye = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="21.081" height="18.024" viewBox="0 0 21.081 18.024">
        <path id="Hide" d="M17.775,17.728,1.886,1.7A1,1,0,0,1,3.306.3L19.2,16.321a1,1,0,1,1-1.42,1.408ZM.042,9.251a.693.693,0,0,1,0-.478A11.787,11.787,0,0,1,3.1,4.318L6.242,7.462a4.568,4.568,0,0,0,5.848,5.847l2.534,2.534a11.681,11.681,0,0,1-4.084.9A11.719,11.719,0,0,1,.042,9.251ZM18.067,13.63l-3.188-3.188A4.57,4.57,0,0,0,9.11,4.673L6.571,2.135a11.684,11.684,0,0,1,3.97-.852,11.716,11.716,0,0,1,10.5,7.491.706.706,0,0,1,0,.479,11.789,11.789,0,0,1-2.972,4.378h0ZM10.6,11.824h-.062A2.815,2.815,0,0,1,7.729,9.013c0-.022,0-.042,0-.064L10.6,11.822l0,0Zm2.745-2.912L10.64,6.2a2.818,2.818,0,0,1,2.71,2.707v0Z" fill="#00a5ce" />
      </svg>
    </>
  )
}

export default Closeeye