import React from "react";

interface ImageFieldProps {
    value?: string;
    id?: string;
    altText?: string;
    className?: string;
    triggerEvent?: any
    handleClick?: any;
    crossorigin?: boolean;
    height?: string;
    width?: string;
}

const style: React.CSSProperties = {};

export default function ImageField({
    value = "",
    id = "",
    altText = "",
    height,
    width,
    className = "border-blue form-control shadow-none rounded-2 text-blue",
    triggerEvent,
    handleClick,
    crossorigin
}: ImageFieldProps) {

    return (
        <img
            className={className}
            src={value}
            height={height}
            width={width}
            alt={altText}
            id={id}
            onClick={handleClick}
            {...(crossorigin ? { crossOrigin: "anonymous" } : {})}
        // onClick={handleClick}
        />
    );
}
