import React from 'react'

const Send = () => {
  return (

    <svg version="1.0"
      width="35" height="35" viewBox="0 0 128.000000 128.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
        fill="#00a5ce" stroke="none">
        <path d="M525 1178 c-84 -16 -189 -73 -264 -144 -225 -212 -230 -565 -11 -784
106 -107 237 -160 393 -160 302 0 547 249 547 555 0 68 -33 194 -67 258 -36
67 -153 184 -220 220 -107 57 -254 79 -378 55z m224 -79 c110 -25 227 -109
291 -209 119 -186 86 -439 -77 -596 -213 -204 -570 -158 -727 94 -162 261 -31
609 264 703 62 20 181 24 249 8z"/>
        <path d="M621 821 c-146 -70 -269 -135 -275 -144 -18 -29 4 -47 113 -92 l103
-41 56 -99 c62 -106 82 -126 109 -109 9 6 58 128 116 294 103 292 107 312 61
317 -10 1 -138 -56 -283 -126z m206 -3 c-2 -7 -31 -88 -63 -180 -32 -93 -62
-168 -65 -168 -4 0 -24 30 -44 66 -38 67 -46 74 -160 119 -16 6 14 24 150 91
94 45 174 83 179 83 4 1 6 -5 3 -11z"/>
      </g>
    </svg>

  )
}

export default Send