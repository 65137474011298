import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { selectAuth } from "../../features/auth/AuthSlice";
import ChatsOrganism from "../organisms/chats/ChatsOrganism";
import ChatCLose from "../../utils/icons/ChatCLose";
import ChatsIcon from "../../utils/icons/ChatsIcon";


export const Layout = () => {
  const navigate = useNavigate();
  const authData = useSelector(selectAuth);


  useEffect(() => {
    if (!authData.token) {
      navigate('/login')
    }
  }, [authData]);

  const [showChats, setShowChats] = useState(false);

  const toggleChats = () => {
    setShowChats(!showChats);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="page-header position-sticky page-width">
                  <Header />
                </div>
                <div className="col-md-12">
                  <div className="body-height bg-white rounded-3  page-width">
                    <div className="container-fluid">
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="chat_block">
        <div className=" text-end"><span onClick={toggleChats} className={`${showChats ? "main_chat_icon_close" : "main_chat_icon"}`}>{showChats ? <ChatCLose /> : <ChatsIcon />}</span></div>
        <div className={`chats_dashboard ${showChats ? "" : "d-none"}`}>
          <ChatsOrganism />
        </div>
      </div>
    </>
  );
};
export default Layout;
