import React from "react";
import styles from "./PageNotFount.module.css"
import { useNavigate } from "react-router-dom";
import ImageField from "../../components/atoms/ImageField";


const PageNotFound: React.FC = () => {

  const navigate = useNavigate();
  return (
    <>
      <div className="row mx-0">
        <div className={`col-6 ${styles.imagage_block} pe-5 ps-0`}>
          <ImageField
              altText="404 image"
              className="text-center logo-width"
              value="static/images/404-page-not-found.svg"
          />
        </div>
        <div className="col-6 ps-5 m-auto">
          <p className={`${styles.fournotfour} mb-0`}>404</p>
          <p className={`${styles.pagenotfound}`}>Page not found</p>
          <p className={`${styles.pagecontent}`}>It looks like the page you were searching for is missing or doesn't exist. Let's find a different path to explore together.</p>
          <a className="submit-btn text-decoration-none py-2 cursor-pointer" onClick={() => navigate('/')}>Go Back to Home</a>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;