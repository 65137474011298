import { AES, enc } from "crypto-ts";

const key = process.env.REACT_APP_SALT_KEY;
if (key) {
  const iv = key.slice(0, 16);
  var parsedKey = enc.Utf8.parse(key);
  var parsedIv = enc.Utf8.parse(iv);
}

const EncryptDecryptService = {
  encryptData: (data: string): string => {
    const ciphertext = AES.encrypt(data, parsedKey, {
      iv: parsedIv,
    }).toString();
    return ciphertext;
  },

  decryptData: (encryptedData: string): string => {
    const decrypted = AES.decrypt(encryptedData, parsedKey, { iv: parsedIv });
    const decryptedData = decrypted.toString(enc.Utf8);
    return decryptedData;
  },
};

export default EncryptDecryptService;
