import { removeUserDetails, selectAuth } from '../features/auth/AuthSlice';
import EncryptDecryptService from '../services/EncryptDecryptService';
import store from '../store';

/**
 * Below is the usage of service function
 * import APICALL from {../give-proper-path-whearver you are importing it}
 * URLENDPOINT is the ENDPOINT of the API CAll
 * httpmethod can be GET, PUT, POST, DELETE etc.
 * data is the json object which you want to send to the backend using post, put, delete and update methods
 * APICALL.service(URLENDPOINT, httpmethod, data)
 *  .then(result => {
 *  use ur result.json()  object here whatever you want to do.
 * })  
 */

/*
*Call to the API
*@param urlendpoint=urlendpoint of the API
*@param httpmethod=METHOD
*@param data=data to the API
*@returns response from the APIsettings
*/
function serviceForSitesJSON(urlendpoint = '', httpmethod = '', data = '', encrypt = false) {
    // Default options are marked with *
    const requestOptions: any = headers(data, httpmethod, encrypt);
    return fetch(process.env.REACT_APP_serverURL + urlendpoint, requestOptions)
        .then(
            // parses JSON response into native Javascript objects
            result => result.json()
        )
        .then(
            result => { return result }
        );
}

// const state = store.getState();
// const user = selectAuth(state);

/*
 *Call to the API
 *@param urlendpoint=urlendpoint of the API
 *@param httpmethod=METHOD
 *@param data=data to the API
 *@returns response from the API
*/
async function service(urlendpoint: string = '', httpmethod: string = '', data: any = '', encrypt = false) {
    const loadingIcon = document.getElementById("loading-div-id");
    if (loadingIcon !== null)
        loadingIcon.setAttribute("style", "display:block;");
    const requestOptions: any = await headers(data, httpmethod, encrypt);

    return fetch(urlendpoint, requestOptions)
        .then(
            async (result) => {
                if (loadingIcon !== null)
                    loadingIcon.setAttribute("style", "display:none;");
                if (encrypt) {
                    let data = await result.json()
                    return JSON.parse(EncryptDecryptService.decryptData(data))
                }
                return result.json()
            }
        ).catch((error) => {
            if (loadingIcon !== null)
                loadingIcon.setAttribute("style", "display:none;");
            return null;
        })
}
/*
*Getting headers for the Ajax
*@param data =data to the API
*@param httpmethod=METHOD
*returns headers for the Ajax
*/

const headers = async (data: any, httpmethod: any, encrypt: boolean): Promise<any> => {
    // let token = await GenerateRefreshToken();
    const user = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}');
    
    const requestOptions: RequestInit = {
        method: httpmethod, // Replace with the desired HTTP method, such as 'GET', 'POST', etc.
        mode: "cors",
        headers: {
            // 'Access-Control-Allow-Origin': '*',
            "Accept": "application/json",
            "Authorization": `Bearer ${user?.token}`
        },
    };

    if (user?.language) {
        requestOptions.headers = {
            ...requestOptions.headers,
            "User-Language": user?.language,
        };
    }
    // Only add Content-Type header if the data is not FormData
    if (!(data instanceof FormData)) {
        requestOptions.headers = {
            ...requestOptions.headers,
            "Content-Type": "application/json",
        };
    }
    if (httpmethod !== 'GET') {
        let reqdata = data;
        if (encrypt) {
            reqdata.data = EncryptDecryptService.encryptData(JSON.stringify(reqdata.data));
        }
        // Set the body conditionally
        requestOptions.body = data instanceof FormData ? data : JSON.stringify(reqdata);
    }

    return requestOptions;
};



/*
this is for the fetch which will return the sites JSON,which will be executed bofore fetching the ,
since at that time we're using env file to get the backend url,
therefore writing a separate function for that.
*/
export const APICALL = {
    service,
    serviceForSitesJSON,
    headers
};
