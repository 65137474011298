import React from 'react'
import ChatsOrganism from '../../components/organisms/chats/ChatsOrganism'

const Chats = () => {
  return (
    <>
        <ChatsOrganism />
    </>
  )
}

export default Chats