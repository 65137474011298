
export const SWITCH_TAB = "SWITCH_TAB";
export const SET_OPTIONS = "SET_OPTIONS";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const SEARCH_OPTIONS = "SEARCH_OPTIONS"
export const SET_EDIT_OPTIONS = "SET_EDIT_OPTIONS";
export const SET_ERROR = 'SET_ERROR';

export type FormAction =
    | 
        { 
            type: typeof SET_OPTIONS | typeof SEARCH_OPTIONS | typeof UPDATE_FIELD | typeof SET_EDIT_OPTIONS;
            field?: string;
            value: any;
        }
    | 
        { 
            type: typeof SWITCH_TAB | typeof SEARCH_OPTIONS;
            value: any;
        }
    | 
        {
            type: typeof SET_ERROR;
            field?: string;
            tab?: string;
            errors?: any;
            hasError?: boolean;
        }