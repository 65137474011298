import React from 'react'

interface Props {
  liked?: boolean;
}

const Like: React.FC<Props> = ({
  liked = false
}) => {
  return (
    <svg height="20" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.692 14.115">
      <path id="Like" d="M40.787,927.3h0a3.969,3.969,0,0,1,2.076-4.2c1.335-.642,3.207-.486,4.934,1.279l.536.548.536-.548c1.727-1.765,3.6-1.921,4.934-1.279a3.969,3.969,0,0,1,2.076,4.2h0c-.279,2.3-2.233,5.344-7.545,7.92C43,932.592,41.072,929.648,40.787,927.3Z" transform="translate(-39.987 -921.941)" fill={`${liked ? '#00a5ce' : 'none'}`} stroke="#00a5ce" stroke-width="1.5" />
    </svg>
  )
};

export default Like;