import { UserNameAndProfileProps } from "../../../../pages/news/context/Interfaces";
import CommonServices from "../../../../services/CommonService";
import ImageField from "../../../atoms/ImageField";
import LabelField from "../../../atoms/LabelField";

const UserNameAndProfile: React.FC<UserNameAndProfileProps> = ({
    user,
    created_at
}) => {
    
    return (
        <div className="row">
            <div className="col-1 text-center">
                <ImageField
                    className={`thumbnail-image border rounded-circle`}
                    value={user?.profile_picture?.file_path ?? "../static/images/profile.png"}
                    altText={'profile'}
                />
            </div>
            <div className="flex-1 user_name_and_date ">
                <div>
                    <LabelField
                        title={`${user?.first_name} ${user?.last_name}`}
                    />
                </div>
                <div>
                    <LabelField
                        title={CommonServices.formatDateIntoLocalDateTime(created_at)}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserNameAndProfile;