import FormValidation from "./Validation";

export const inputValidation = (value: any, type: any, length = 255) => {
    switch (type) {
        case 'required_text':
            return FormValidation.nameValidation(value, length);

        case 'not_required_text':
            return FormValidation.textValidation(value, length);

        case 'range':
        case 'number':
            return FormValidation.numberValidation(value);

        case 'textarea':
            return FormValidation.textareaValidation(value);

        case 'color':
            return FormValidation.emptyValidation(value);

        case 'select':
            return FormValidation.selectValidation(value);

        case 'multiselect':
            return FormValidation.multiselectValidation(value);

        case 'zipcode':
            return FormValidation.zipcodeValidation(value);
        
        case 'phone':
            return FormValidation.phoneValidation(value);

        case 'file':
            return FormValidation.fileValidation(value);
        
        case 'email':
            return FormValidation.emailValidation(value);

        default:
            return null;
    }
};