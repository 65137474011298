import CreateNewsOrganism from '../../components/organisms/news/create/CreateNewsOrganism';
import { NewsProvider } from './context/NewsContext';

const CreateNews = () => {
    return (
        <NewsProvider>
            <CreateNewsOrganism />
        </NewsProvider>
    );
};

export default CreateNews;
