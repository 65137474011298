import React, { ChangeEvent } from 'react';
import LabelField from './LabelField';

interface SwitchProps {
    id: string;
    name: string;
    title: string;
    checked: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    key?: string;
    toggleclassName?: string;
}

const ToggleSwitch: React.FC<SwitchProps> = ({ id, name, title, checked, onChange, toggleclassName, key }) => {

    return (
        <div key={key} className={`d-flex align-items-center ${toggleclassName}`}>
            <LabelField
                title={title}
            />
            <label className="switch mx-2">
                <input
                    type="checkbox"
                    id={id}
                    name={name}
                    checked={checked}
                    onChange={onChange}
                />
                <span className="slider"></span>
            </label>
        </div>
    );
};

export default ToggleSwitch;
